/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ChargePolicy = {
    readonly id?: number;
    name?: string | null;
    valid_from?: string | null;
    valid_to?: string | null;
    chargeType: ChargePolicy.chargeType;
    /**
     * €/kWh
     */
    kWhPrice?: string | null;
    tieredPricing?: boolean;
    tiers?: any;
    flatFee?: boolean;
    /**
     * €
     */
    flatFeePrice?: string | null;
    discount?: boolean;
    discountType: ChargePolicy.discountType;
    discountValue?: string;
    additionalCharges?: boolean;
    additionalChargesInfo?: any;
    additionalBenefits?: boolean;
    additionalBenefitsInfo?: any;
    readonly last_updated?: string;
    company?: number | null;
    policyGroups?: Array<number | null>;
    applyTo?: Array<number | null>;
};

export namespace ChargePolicy {

    export enum chargeType {
        TIME = 'time',
        ENERGY = 'energy',
    }

    export enum discountType {
        PERCENTAGE = 'percentage',
        PER_KWH = 'perKwh',
    }


}
