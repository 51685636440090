import { faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

function OpenFiltersMenuBtn({ setOpen }) {
    return (
        <button
            className="btn btn-primary btn-circle "
            onClick={() => setOpen(true)}
        >
            <FontAwesomeIcon icon={faBars} className="text-white" />
        </button>
    )
}

export { OpenFiltersMenuBtn }
