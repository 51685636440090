// Create zustand store for geocoding

import { create } from 'zustand'
import { useQuery } from '@tanstack/react-query'
import { geocode, NominatimAddressData } from './nominatim'

interface GeocodingState {
    searchTerm: string
    setSearchTerm: (searchTerm: string) => void

    selectedResult: NominatimAddressData | null
    setSelectedResult: (result: NominatimAddressData | null) => void
    dialogOpen: boolean
    setDialogOpen: (dialogOpen: boolean) => void
}

export const useGeocodingStore = create<GeocodingState>((set) => ({
    searchTerm: '',
    setSearchTerm: (searchTerm) => set({ searchTerm }),

    selectedResult: null,
    setSelectedResult: (selectedResult) => set({ selectedResult }),
    dialogOpen: false,
    setDialogOpen: (dialogOpen) => set({ dialogOpen }),
}))

export const useGeocoding = () => {
    const {
        searchTerm,
        setSearchTerm,
        selectedResult,
        setSelectedResult,
        dialogOpen,
        setDialogOpen,
    } = useGeocodingStore()

    const fetch = async () => {
        if (searchTerm.length > 3) {
            return await geocode({ query: searchTerm })
        }
    }
    const { refetch, data, isLoading, isError } = useQuery({
        queryFn: fetch,
        queryKey: ['geocode', searchTerm],
        enabled: false,
    })

    return {
        performSearch: refetch,
        searchTerm,
        setSearchTerm,
        results: data,
        isLoading,
        isError,
        selectedResult,
        setSelectedResult,
        dialogOpen,
        setDialogOpen,
    }
}
