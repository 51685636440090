/*
 * Set the map style based on the time of day
 * @returns {string} - the map style name
 */
export function styleBasedOnTime() {
    const date = new Date()
    const hour = date.getHours()
    if (hour >= 6 && hour < 18) {
        return 'basic'
    } else {
        return 'black'
    }
}
