import { useContext, useEffect, useState } from 'react'
import { AuthContext } from '@/modules/auth/context'

interface OcpiDomainValueChoice {
    value: number
    name: string
    label_gr: string
    label_en: string
    description_gr: string
    description_en: string
}

interface OcpiDomainValues {
    tariff_types: OcpiDomainValueChoice[]
    connector_standards: OcpiDomainValueChoice[]
    connector_formats: OcpiDomainValueChoice[]
    connector_power_types: OcpiDomainValueChoice[]
    evse_statuses: OcpiDomainValueChoice[]
    evse_parking_restrictions: OcpiDomainValueChoice[]
    evse_capabilities: OcpiDomainValueChoice[]
}

/**
 * Hook to fetch and store OCPI domain values from the API
 * Choices for the following fields are fetched:
 * - Tariff types (tariff_types), e.g. Regular, Dynamic, Flat
 * - Connector standards (connector_standards), e.g. CHAdeMO, CCS, Type 2
 * - Connector formats (connector_formats) , e.g. Cable, socket
 * - Connector power types (connector_power_types), e.g. AC, AC 3-Phase, DC
 * - EVSE statuses (evse_statuses) , e.g. Available, Charging, Out of order
 * - EVSE parking restrictions (evse_parking_restrictions), e.g. No parking, Customer only
 * - EVSE capabilities (evse_capabilities), e.g. Reservable, Public
 * @returns Choices for the above fields
 */
export const useOcpiChoices = (): OcpiDomainValues | null => {
    const { AuthenticatedAPI } = useContext(AuthContext)
    const [choices, setChoices] = useState<OcpiDomainValues>(null)

    // Fetch choices from local storage if available when the component mounts
    useEffect(() => {
        const storedChoices = localStorage.getItem('choices')

        const choices = storedChoices ? JSON.parse(storedChoices) : null

        // TODO: this is a temporary fix to refresh the choices (if user has cached choices
        // from previous backend version) if no timestamp, or timestamp before June 11 2024,
        // refresh choices
        const refresh = choices
            ? !choices.timestamp ||
              choices.timestamp < Date.parse('11 Jun 2024 13:00:00 GMT')
            : false

        if (choices && !refresh) {
            setChoices(choices)
        } else {
            // Fetch choices from the API if not available in local storage
            AuthenticatedAPI.ocpi
                .ocpiDomainValuesList({
                    tariffTypes: true,
                    connectorStandards: true,
                    connectorFormats: true,
                    connectorPowerTypes: true,
                    evseStatuses: true,
                    evseParkingRestrictions: true,
                    evseCapabilities: true,
                })
                .then((apiChoices) => {
                    setChoices(apiChoices)

                    // Save choices in local storage
                    const storeChoices = {
                        ...apiChoices,
                        timestamp: Date.now(),
                    }
                    localStorage.setItem(
                        'choices',
                        JSON.stringify(storeChoices)
                    )
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }, [])

    return choices
}
