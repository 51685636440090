import location_badge from '@/assets/img/location-badge.png'
import location_selected_badge from '@/assets/img/location-selected-badge.png'

const icons = {
    'location-badge': location_badge,
    'location-selected-badge': location_selected_badge,
}

function loadIcons(map) {
    // Wrap in try/catch to prevent page from crashing when map is re-initialized (e.g. window resize)
    try {
        for (const [name, icon] of Object.entries(icons)) {
            // if image already exists, remove it
            if (map.hasImage(name)) {
                map.removeImage(name)
            }

            const newImg = map
                .loadImage(icon)
                .then((img) => {
                    if (map.hasImage(name)) {
                        map.removeImage(name)
                    }
                    map.addImage(name, img.data)
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    } catch (err) {
        null
    }
}

export { icons, loadIcons }
