import { VehiclesService } from '@/api/services/VehiclesService'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useApi, useUser } from '@/modules/auth'
import { useCallback } from 'react'
import type { EV } from '@/api'

interface UserVehicleResponse {
    is_primary: boolean
    properties: EV
}

/**
 * Create a user-dependent query key
 * @returns
 */
const useUserQueryKey = () => {
    const user = useUser()
    return user?.id?.toString() || ('anonymous' as const)
}

/**
 * Get current user's vehicles
 */
export function useUserVehicles() {
    const ApiClient = useApi()
    const userQK = useUserQueryKey()

    const fetchUserVehicles = useCallback(() => {
        return ApiClient.vehicles.vehiclesUserList()
    }, [ApiClient])

    return useQuery<UserVehicleResponse[]>({
        queryKey: [userQK, 'vehicles'],
        queryFn: fetchUserVehicles,
        enabled: userQK !== 'anonymous',
    })
}

/**
 * Search commercially available vehicles from EV Database
 * @returns
 */
export function useSearchVehicles(
    search_params: Parameters<VehiclesService['vehiclesSearchList']>[0]
) {
    const ApiClient = useApi()

    const fetchVehicles = useCallback(
        (params: Parameters<VehiclesService['vehiclesSearchList']>[0]) => {
            return ApiClient.vehicles.vehiclesSearchList({
                ...params,
                q: params.q?.replace(/\s/g, ''), // remove spaces
            })
        },
        [ApiClient]
    )

    return useQuery({
        queryKey: ['searchVehicles', search_params.q],
        queryFn: () => fetchVehicles(search_params),
        enabled: !!search_params.q && search_params.q.length > 3,
        staleTime: 1000 * 60 * 60, // 1 hour
    })
}

export function useAssignVehicle() {
    const ApiClient = useApi()
    const userQK = useUserQueryKey()
    const queryClient = useQueryClient()

    const assignRequest = useCallback(
        (vuuid: string) => {
            return ApiClient.vehicles.vehiclesUserAssignCommercial({
                uuid: vuuid,
            })
        },
        [ApiClient]
    )

    return useMutation({
        mutationFn: assignRequest,
        onSuccess: () => {
            queryClient.invalidateQueries([userQK, 'vehicles'] as any)
        },
    })
}

export function useSetDefaultVehicle() {
    const ApiClient = useApi()
    const userQK = useUserQueryKey()
    const queryClient = useQueryClient()

    const setDefaultRequest = useCallback(
        (vuuid: string) => {
            return ApiClient.vehicles.vehiclesUserSetDefault({
                uuid: vuuid,
            })
        },
        [ApiClient]
    )

    return useMutation({
        mutationFn: setDefaultRequest,
        onSuccess: () => {
            queryClient.invalidateQueries([userQK, 'vehicles'] as any)
        },
    })
}
