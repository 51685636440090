import maplibregl from 'maplibre-gl'
import { useEffect, useContext, useState } from 'react'

import { DataContext } from '@/modules/core/context/DataContext'
import { useMap } from '@/modules/map'
import { RoutingContext } from '@/packages/routing/context'

import { icons, loadIcons } from '@/modules/core/lib/LoadIcons'
import bbox from '@turf/bbox'

function addLayers({ map, layerName, dataSource }) {
    // Add cluster layer
    map.addLayer({
        id: `${layerName}-cluster`,
        type: 'circle',
        source: dataSource,
        filter: ['has', 'point_count'],
        paint: {
            'circle-radius': [
                'step',
                ['get', 'point_count'],
                8,
                10,
                10,
                50,
                12,
                100,
                16,
                750,
                20,
            ],
            'circle-color': '#007cbf',
            'circle-stroke-color': '#fff',
            'circle-stroke-width': 1,
        },
    })

    // Add cluster count layer
    map.addLayer({
        id: `${layerName}-cluster-count`,
        type: 'symbol',
        source: dataSource,
        filter: ['has', 'point_count'],
        layout: {
            'text-field': '{point_count_abbreviated}',
            'text-font': ['Noto Sans Bold'],
            'text-size': 12,
        },
        paint: {
            'text-color': 'white',
        },
    })

    // Add unclustered layer
    map.addLayer({
        id: `${layerName}-unclustered`,
        type: 'circle',
        source: dataSource,
        filter: ['!', ['has', 'point_count']],
        paint: {
            'circle-radius': [
                'case',
                ['boolean', ['feature-state', 'isSelected'], false],
                7,
                ['case', ['boolean', ['feature-state', 'hover'], false], 5, 3],
            ],
            'circle-color': [
                'case',
                ['boolean', ['feature-state', 'isSelected'], false],
                'yellow',
                '#9a18aa',
            ],
            'circle-stroke-color': [
                'case',
                ['boolean', ['feature-state', 'isSelected'], false],
                '#9a18aa',
                '#fff',
            ],
            'circle-stroke-width': [
                'case',
                ['boolean', ['feature-state', 'hover'], false],
                3,
                1,
            ],
        },
    })

    // Add symbol layer for unclustered points
    map.addLayer({
        id: layerName,
        type: 'symbol',
        source: dataSource,
        //minzoom: 10,
        filter: ['!', ['has', 'point_count']],
        layout: {
            'icon-image': 'location-badge', // Use your badge image name here

            'icon-rotation-alignment': 'viewport',
            'symbol-spacing': 1,
            'text-field': [
                'concat',
                ['to-string', ['get', 'charging_cost']],
                ' €',
            ],
            'text-font': ['Noto Sans Bold'],
            'icon-offset': [0, -22],
            'text-offset': [0, -2.3],
            'text-rotation-alignment': 'viewport',
            'text-size': 9,
            'icon-size': 1,
            'icon-allow-overlap': true,
            'symbol-sort-key': ['get', 'charging_cost'],
        },
        paint: {
            'text-color': 'white',
            'text-halo-color': '#9a18aa',
            'text-halo-width': 0.3,
            'icon-opacity': [
                'case',
                ['boolean', ['feature-state', 'isSelected'], false],
                1,
                [
                    'case',
                    ['boolean', ['feature-state', 'hover'], false],
                    1,
                    0.8,
                ],
            ],
        },
    })

    return [
        `${layerName}-cluster`,
        `${layerName}-cluster-count`,
        `${layerName}-unclustered`,
        layerName,
    ]
}

function deselectAllFeatures(map, layerName) {
    // Check if layer exists
    if (!map.getLayer(layerName)) return

    const allFeatures = map.queryRenderedFeatures({ layers: [layerName] })
    allFeatures.forEach((feature) => {
        map.setFeatureState(
            {
                source: feature.source,
                sourceLayer: feature.sourceLayer,
                id: feature.id, // properties.id
            },
            { isSelected: false }
        )
    })
}

function clusterClickInteraction(map, dataSource, layerName) {
    const zoomToCluster = (e) => {
        const features = map.queryRenderedFeatures(e.point, {
            layers: [layerName],
        })

        const clusterId = features[0].properties.cluster_id

        // async: https://maplibre.org/maplibre-gl-js/docs/API/classes/GeoJSONSource/#getclusterexpansionzoom
        map.getSource(dataSource)
            .getClusterExpansionZoom(clusterId)
            .then((zoom) => {
                map.easeTo({
                    center: features[0].geometry.coordinates,
                    zoom: zoom,
                })
            })
            .catch((error) => console.log('Error:', error))
    }

    // Event listeners
    map.on('click', layerName, (e) => {
        zoomToCluster(e)
    })

    return zoomToCluster
}

function clickInteraction(map, layerName, clickCallback) {
    const clearSelection = () => {
        // Update React State
        clickCallback(null)

        // Update maplibre state
        deselectAllFeatures(map, layerName)
    }

    const setSelectedFeature = (e) => {
        clearSelection()

        const clickedFeatures = map.queryRenderedFeatures(e.point, {
            layers: [layerName],
        })

        if (clickedFeatures.length > 0) {
            const clickedFeature = clickedFeatures[0]
            const featureId = clickedFeature.id // properties.id;

            // Update React State
            clickCallback(clickedFeature)

            // Update maplibre state
            map.setFeatureState(
                {
                    source: clickedFeature.source,
                    id: featureId,
                },
                {
                    isSelected: true,
                }
            )
        }
    }

    // Event listeners
    map.on('click', (e) => {
        clearSelection()
    })

    map.on('click', layerName, (e) => {
        setSelectedFeature(e)
    })

    return clearSelection, setSelectedFeature
}

function hoverInteraction(map, layerName) {
    // Create a popup object
    const popup = new maplibregl.Popup({
        closeButton: false,
        closeOnClick: false,
        className: 'hover-popup',
        offset: [0, -15],
    })

    const setState = (e) => {
        // Unhighlight all features
        unsetState()

        const hoveredFeatures = map.queryRenderedFeatures(e.point, {
            layers: [layerName],
        })
        if (hoveredFeatures.length > 0) {
            map.getCanvas().style.cursor = 'pointer'

            const hoveredFeature = hoveredFeatures[0]
            const featureId = hoveredFeature.id // properties.id;

            map.setFeatureState(
                {
                    source: hoveredFeature.source,
                    sourceLayer: hoveredFeature.sourceLayer,
                    id: featureId,
                },
                {
                    hover: true,
                }
            )
        } else {
            map.getCanvas().style.cursor = ''
        }
    }

    const unsetState = (e) => {
        const allFeatures = map.queryRenderedFeatures({ layers: [layerName] })
        allFeatures.forEach((feature) => {
            map.setFeatureState(
                {
                    source: feature.source,
                    sourceLayer: feature.sourceLayer,
                    id: feature.id, // properties.id
                },
                { hover: false }
            )
        })
    }

    const addPopup = (e) => {
        // Clear existing popups
        popup.remove()

        // Get the feature properties
        const properties = e.features[0].properties

        // Set the popup content
        try {
            popup
                .setLngLat(e.lngLat)
                .setHTML(
                    `
                    <div class='w-68 mx-2 flex flex-col'>
                        <div class='flex flex-row gap-2'>

                            
                                ${
                                    properties.company_img
                                        ? "<div class='m-auto'> <img class='m-auto' src='https://electrokinisi.yme.gov.gr/public/images/" +
                                          properties?.company_img +
                                          "' alt='' width='40' /> </div>"
                                        : ''
                                }
                            


                            <div class='pl-2 border-l border-base-200'>
                                <h3 class=''>
                                    ${properties.name} 
                                </h3>
                                <h3 class='font-normal text-xs mr-auto text-gray-500'>
                                    ${properties.operator}
                                </h3>
                            </div>

                        </div>

                        <h3 class='text-secondary m-auto '>Βασική χρέωση 
                            <span class='text-lg font-bold'> ${
                                properties.charging_cost
                                    ? properties.charging_cost
                                    : '-'
                            } </span>
                            €/kWh 
                        </h3>

                    </div>
                    `
                )
                .addTo(map)
        } catch {
            null
        }
    }

    const removePopup = (e) => {
        popup.remove()
    }

    const onEnter = (e) => {
        setState(e)
        addPopup(e)
    }

    const onLeave = (e) => {
        unsetState(e)
        removePopup(e)
    }

    map.on('mousemove', layerName, onEnter)
    map.on('mouseleave', layerName, onLeave)

    return popup
}

function DataLayer() {
    const { data, selectedLocation, setSelectedLocation } =
        useContext(DataContext)

    const { currentMap } = useMap()
    const { routeEditing } = useContext(RoutingContext)

    const [source, setSource] = useState(null)

    const layerName = 'locations'
    const sourceName = 'locations-source'

    useEffect(() => {
        loadIcons(currentMap, icons)
    }, [currentMap])

    // When selection changes, set map state
    useEffect(() => {
        if (
            !currentMap ||
            currentMap === null ||
            currentMap.style === undefined
        ) {
            return
        }
        if (
            !currentMap.getSource(sourceName) ||
            !currentMap.getLayer(layerName)
        )
            return

        // Deselect all
        deselectAllFeatures(currentMap, layerName)

        // If no location is selected, we're done
        if (!selectedLocation) return

        // Select the feature
        currentMap.setFeatureState(
            {
                source: sourceName,
                id: selectedLocation.id,
            },
            {
                isSelected: true,
            }
        )
    }, [selectedLocation])

    // When data changes, change the Source definition, and fly to the bounds
    useEffect(() => {
        if (!data) return
        setSource({
            type: 'geojson',
            data,
            cluster: true,
            clusterMaxZoom: 14,
            clusterRadius: 15,
            // "promoteId": "id" // use properties.id as id for feature state
        })

        // Fly to the bounds of the data
        const bounds = bbox(data)
        try {
            currentMap.fitBounds(bounds, {
                padding: 100,
                maxZoom: 14,
                duration: 2000,
            })
        } catch {
            console.log(`Error: invalid bounds ${bounds}`)
        }
    }, [data])

    // When the map loads, or the source changes, add the source
    useEffect(() => {
        if (!source) return

        currentMap.addSource(sourceName, source)
        const layers = addLayers({
            map: currentMap,
            layerName,
            dataSource: sourceName,
        })

        clickInteraction(currentMap, layerName, setSelectedLocation)
        clusterClickInteraction(currentMap, sourceName, `${layerName}-cluster`)
        hoverInteraction(currentMap, layerName)

        // Clear the source on unmount/refresh
        return () => {
            layers.forEach((layer) => {
                currentMap.removeLayer(layer)
            })
            currentMap.removeSource(sourceName)
        }
    }, [source])

    // When route editing is toggled, change the layer visibility
    useEffect(() => {
        if (!currentMap) return
        if (!currentMap.style) return
        if (!currentMap.getLayer(layerName)) return

        currentMap.setLayoutProperty(
            layerName,
            'visibility',
            routeEditing ? 'none' : 'visible'
        )
    }, [routeEditing])

    // Clear component on unmount of map
    // useEffect(() => {
    //    if (!currentMap || currentMap === null){
    //
    //    }
    //
    // }, [currentMap])
}

export { DataLayer }
