/**
 * Map style configuration
 *
 * Glyphs Original: 'https://cdn.protomaps.com/fonts/pbf/{fontstack}/{range}.pbf'
 * Glyphs Using basemaps: 'https://protomaps.github.io/basemaps-assets/fonts/{fontstack}/{range}.pbf'
 *
 */
import { layers as basic } from './basic.style.js'
import { layers as grayscale } from './grayscale.style.js'
import { layers as black } from './black.style.js'

const availableStyles = {
    basic: {
        label: 'Βασικός',
        name: 'basic',
        styleJson: basic,
    },
    grayscale: {
        label: 'Χαμηλή όχληση (Ασπρόμαυρο)',
        name: 'grayscale',
        styleJson: grayscale,
    },
    black: {
        label: 'Νυκτός',
        name: 'black',
        styleJson: black,
    },
}

const sprites = `${window.location.origin}/${
    import.meta.env.VITE_FRONTEND_ROOT || 'app'
}/sprites/osm-liberty`
const glyphs = `${window.location.origin}/${
    import.meta.env.VITE_FRONTEND_ROOT || 'app'
}/fonts/{fontstack}/{range}.pbf`

const styleSkeleton = {
    version: 8,
    glyphs: glyphs,
    sprite: sprites,
    sources: {
        'eox-basemap': {
            type: 'raster',
            tiles: [
                'https://tiles.maps.eox.at/?&service=WMS&request=GetMap&layers=s2cloudless_3857&styles=&format=image%2Fjpeg&transparent=false&version=1.1.1&tiled=true&dpi=120&map_resolution=120&width=256&height=256&srs=EPSG%3A3857&bbox={bbox-epsg-3857}',
            ],
            tileSize: 256,
            attribution:
                "<a href='https://s2maps.eu/' target='_blank'>Sentinel-2 cloudless</a> by <a href='https://eox.at/' target='_blank'>EOX IT Services GmbH</a> (Contains modified Copernicus Sentinel data 2016 & 2017)",
        },
        protomaps: {
            type: 'vector',
            tiles: [`${window.location.origin}/maptiles/greece/{z}/{x}/{y}`],
            maxzoom: 15,
            attribution:
                '<a href="https://protomaps.com">Protomaps</a> © <a href="https://openstreetmap.org">OpenStreetMap</a>',
        },
    },
}

/**
 * Returns a list of available style ids
 *
 * @returns {Array<string>}
 */
function listStylesIds() {
    return Object.keys(availableStyles)
}

/**
 * Returns the details of a style (label, name, styleJson)
 *
 * @param {string} styleId
 * @returns
 */
function styleDetails(styleId) {
    return availableStyles[styleId]
}

/**
 * Returns the style JSON for a given styleId
 * @param {string} styleId
 */
function getStyleJson(styleId) {
    if (!styleId) styleId = 'basic'

    return {
        ...styleSkeleton,
        layers: availableStyles[styleId].styleJson,
    }
}

export { getStyleJson, listStylesIds, styleDetails }
