import React from 'react'
import { LoadingScreen } from './LoadingScreen'

function Suspended({ children }) {
    return (
        <React.Suspense fallback={<LoadingScreen />}>{children}</React.Suspense>
    )
}

export default Suspended
