import React, { createContext, useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import maplibregl from 'maplibre-gl'
import { useShallow } from 'zustand/react/shallow'
import {
    useUserPosition,
    useUserPositionStore,
} from '@/packages/position/context'
import { DataContext } from '@/modules/core/context/DataContext'
import { useMap } from '@/modules/map'
import MapLibreGlDirections from '@maplibre/maplibre-gl-directions'

import type { Route } from './types'

type OriginDest = [number, number]
type OriginMode = 'map' | 'user-location' | 'geocode'
type DestMode = 'map' | 'charging-location' | 'geocode'

interface RoutingContextType {
    origin: OriginDest | null
    setOrigin: (origin: OriginDest) => void
    destination: OriginDest | null
    setDestination: (destination: OriginDest) => void
    originMode: OriginMode
    setOriginMode: (mode: OriginMode) => void
    destinationMode: DestMode
    setDestinationMode: (mode: DestMode) => void
    addingOrigin: boolean
    setAddingOrigin: (adding: boolean) => void
    addingDestination: boolean
    setAddingDestination: (adding: boolean) => void
    routeEditing: boolean
    setRouteEditing: (editing: boolean) => void
    directions: MapLibreGlDirections | null
    setDirections: (directions: MapLibreGlDirections) => void
    clearRoute: () => void
    routes: Route[]
    selectedRoute: number | undefined
    setSelectedRoute: (index: number | undefined) => void
    getRoute: ({
        origin,
        destination,
    }: {
        origin: OriginDest
        destination: OriginDest
    }) => void
    directionsError: boolean
    setDirectionsError: (error: boolean) => void
    gMapsUrl: string
}

const RoutingContext = createContext<RoutingContextType | undefined>(undefined)

function RoutingProvider({
    selectedLocation,
    children,
}: {
    selectedLocation: any
    children: any
}) {
    const { t } = useTranslation()

    const { currentMap } = useMap()
    const { setParamsMulti } = useContext(DataContext)

    // Routing
    const [routeEditing, setRouteEditing] = useState(false)
    const [directions, setDirections] = useState<MapLibreGlDirections | null>(
        null
    )

    const [origin, setOrigin] = useState<OriginDest | null>(null)
    const [destination, setDestination] = useState<OriginDest | null>(null)

    const [originMode, setOriginMode] = useState<OriginMode>('map') // 'map' or 'user-location'
    const [destinationMode, setDestinationMode] = useState<DestMode>('map') // 'map' or 'charging-location'

    const [addingOrigin, setAddingOrigin] = useState<boolean>(false) // When true, the user is adding an origin
    const [addingDestination, setAddingDestination] = useState<boolean>(false) // When true, the user is adding a destination

    const [routes, _setRoutes] = useState<Route[]>([])
    const [selectedRoute, _setSelectedRoute] = useState<number | undefined>(
        undefined
    )

    const [directionsError, setDirectionsError] = useState(false)

    // Google maps
    const [gMapsUrl, setGMapsUrl] = useState('')

    // Initialization

    // When destinationMode == 'charging-location', change destination to selectedLocation
    useEffect(() => {
        if (destinationMode !== 'charging-location') return
        if (!selectedLocation) return

        setDestination(selectedLocation.geometry.coordinates)
    }, [destinationMode, selectedLocation])

    // When origin and destionation changes, create/update markers on the  map
    useEffect(() => {
        if (!currentMap) return

        let originMarker = null
        let destinationMarker = null

        if (origin) {
            // Add origin marker
            originMarker = new maplibregl.Marker({
                color: '#1f2937',
                draggable: false,
            })
                .setLngLat(origin)
                .addTo(currentMap)
                .on('dragend', (ev) => {
                    setOrigin([
                        ev.target.getLngLat().lng,
                        ev.target.getLngLat().lat,
                    ])
                })
        }

        if (destination) {
            // Add destination marker
            destinationMarker = new maplibregl.Marker({
                color: '#007cbf',
                draggable: false,
            })
                .setLngLat(destination)
                .addTo(currentMap)
                .on('dragend', (ev) => {
                    setDestination([
                        ev.target.getLngLat().lng,
                        ev.target.getLngLat().lat,
                    ])
                })
        }

        return () => {
            originMarker && originMarker.remove()
            destinationMarker && destinationMarker.remove()
        }
    }, [origin, destination])

    // When origin or destination changes, if both are set, get the route
    useEffect(() => {
        if (!directions) return
        if (!origin || !destination) {
            setRouteEditing(false)
            setSelectedRoute(null)
            setRoutes([])
            directions.clear()

            // @ts-ignore
            directions.draw(false)
            return
        }
        getRoute({ origin, destination })
    }, [origin, destination])

    const formatDistance = (distance: number) => {
        return Math.round(distance / 1000)
    }

    const formatDuration = (duration: number | string) => {
        if (typeof duration === 'string') return duration

        const hours = Math.floor(duration / 3600)
        const minutes = Math.round((duration - hours * 3600) / 60)

        let durationString = ''
        if (hours > 0) {
            durationString += hours + ' ' + t('ώρες') + ' '
        }
        if (minutes > 0) {
            durationString += minutes + ' ' + t('λεπτά') + ' '
        }

        return durationString
    }

    // Combine the origin and destination of each leg
    // from the waypoints array
    const addLegSourceDest = (legIdx: number, waypoints: Array<any>) => {
        const originPnt = waypoints[legIdx]
        const destPnt = waypoints[legIdx + 1]

        const origin = {
            name: originPnt.name
                ? originPnt.name
                : originPnt.location.join(','),
            location: originPnt.location,
        }
        const dest = {
            name: destPnt.name ? destPnt.name : destPnt.location.join(','),
            location: destPnt.location,
        }

        return { origin, dest }
    }

    // Internally set the routes array, after formatting
    const setRoutes = (routes: Route[] | undefined, waypoints?: any) => {
        if (!routes) setRoutes([])

        // Format the distance and duration of each route in routes array
        const _routes = routes?.map((route) => {
            const distance = formatDistance(route.distance)
            const duration = formatDuration(route.duration)

            const legs = waypoints
                ? route.legs?.map((leg, index) => {
                      const { origin, dest } = addLegSourceDest(
                          index,
                          waypoints
                      )
                      return {
                          ...leg,
                          distance: formatDistance(leg.distance),
                          duration: formatDuration(leg.duration),
                          origin,
                          destination: dest,
                      }
                  })
                : null

            return {
                ...route,
                legs,
                distance,
                duration,
            }
        })

        _setRoutes(_routes)
    }

    const setSelectedRoute = (index: number | undefined) => {
        _setSelectedRoute(index)

        // Set the selected route to be drawn on the map
        // @ts-ignore
        directions.selectedRouteIndex = index

        // @ts-ignore
        directions.draw(false)
    }

    const clearRoute = () => {
        if (!directions) return
        directions.clear()
        setRoutes([])
        setSelectedRoute(undefined)
    }

    const _computeRoute = (origin: OriginDest, destination: OriginDest) => {
        if (!directions) return
        clearRoute()

        directions.setWaypoints([origin, destination])

        // Routing Completed
        directions.on('fetchroutesend', (ev) => {
            setRoutes(ev.data?.routes, ev.data?.waypoints)
            setSelectedRoute(0)
        })
    }

    const getRoute = ({
        destination,
        origin,
    }: {
        destination: OriginDest
        origin: OriginDest
    }) => {
        try {
            _computeRoute(origin, destination)
        } catch (error) {
            setDirectionsError(true)
            console.log('Error setting waypoints')
        }
    }

    useEffect(() => {
        if (!directions) return
        directions.interactive = routeEditing
        directions.draw(false)
    }, [routeEditing])

    // Update search parameters when routes change
    useEffect(() => {
        if (!routes || routes.length === 0 || !origin || !destination) {
            setParamsMulti([
                { name: 'startLng', value: null },
                { name: 'startLat', value: null },
                { name: 'endLng', value: null },
                { name: 'endLat', value: null },
                { name: 'routeNo', value: null },
            ])
            return
        }

        const startLng = origin[0]
        const startLat = origin[1]
        const endLng = destination[0]
        const endLat = destination[1]
        setParamsMulti([
            { name: 'startLng', value: startLng },
            { name: 'startLat', value: startLat },
            { name: 'endLng', value: endLng },
            { name: 'endLat', value: endLat },
            { name: 'routeNo', value: selectedRoute },
        ])

        return () => {
            setParamsMulti([
                { name: 'startLng', value: null },
                { name: 'startLat', value: null },
                { name: 'endLng', value: null },
                { name: 'endLat', value: null },
                { name: 'routeNo', value: null },
            ])
        }
    }, [routes, selectedRoute])

    // Google maps
    useEffect(() => {
        if (!directions) return

        const getUrl = () => {
            const waypoints = directions.waypoints

            if (!waypoints || waypoints.length < 2) return ''

            const startLat = waypoints[0][1]
            const startLng = waypoints[0][0]
            const destLat = waypoints[waypoints.length - 1][1]
            const destLng = waypoints[waypoints.length - 1][0]
            const mapsURL = `https://www.google.com/maps/dir/${startLat},${startLng}/${destLat},${destLng}`
            return mapsURL
        }

        setGMapsUrl(getUrl())
    }, [selectedRoute, directions])

    // Create the context value object
    const routingContextValue = {
        origin,
        setOrigin,
        destination,
        setDestination,
        originMode,
        setOriginMode,
        destinationMode,
        setDestinationMode,
        addingOrigin,
        setAddingOrigin,
        addingDestination,
        setAddingDestination,
        routeEditing,
        setRouteEditing,
        directions,
        setDirections,
        clearRoute,
        routes,
        selectedRoute,
        setSelectedRoute,
        getRoute,
        directionsError,
        setDirectionsError,
        gMapsUrl,
    }

    return (
        <RoutingContext.Provider value={routingContextValue}>
            {children}
        </RoutingContext.Provider>
    )
}

const useRouting = () => {
    const userPosition = useUserPositionStore(
        useShallow((state) => state.userPosition)
    )
    const { startTracking } = useUserPosition()

    const context = React.useContext(RoutingContext)
    if (context === undefined) {
        throw new Error('useRouting must be used within a RoutingProvider')
    }

    // When originMode == 'user-location', change origin to userPosition
    useEffect(() => {
        if (context.originMode !== 'user-location') return
        if (!userPosition) {
            startTracking()
            return
        }

        context.setOrigin([userPosition.longitude, userPosition.latitude])
    }, [context.originMode, userPosition])

    return context
}

export { RoutingProvider, RoutingContext, useRouting }
