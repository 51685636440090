import { useContext } from 'react'
import { AuthContext } from '@/modules/auth'
import { Navigate, useLocation } from 'react-router-dom'

const ProtectedRoute = ({ children }) => {
    const { user, pending } = useContext(AuthContext)
    const location = useLocation()

    if (!pending && !user.isAuthenticated) {
        return <Navigate to="/login" state={{ from: location }} replace />
    }

    return children
}

export default ProtectedRoute
