/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConnectorFormat } from '../models/ConnectorFormat';
import type { ConnectorPowerType } from '../models/ConnectorPowerType';
import type { ConnectorType } from '../models/ConnectorType';
import type { EVSECapability } from '../models/EVSECapability';
import type { EVSEParkingRestriction } from '../models/EVSEParkingRestriction';
import type { EVSEStatus } from '../models/EVSEStatus';
import type { TariffType } from '../models/TariffType';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OcpiService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns ConnectorFormat 
     * @throws ApiError
     */
    public ocpiConnectorFormatsList(): CancelablePromise<Array<ConnectorFormat>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ocpi/connector-formats/',
        });
    }

    /**
     * @returns ConnectorFormat 
     * @throws ApiError
     */
    public ocpiConnectorFormatsCreate({
data,
}: {
data: ConnectorFormat,
}): CancelablePromise<ConnectorFormat> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/ocpi/connector-formats/',
            body: data,
        });
    }

    /**
     * @returns ConnectorFormat 
     * @throws ApiError
     */
    public ocpiConnectorFormatsRead({
id,
}: {
/**
 * A unique integer value identifying this connector format.
 */
id: number,
}): CancelablePromise<ConnectorFormat> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ocpi/connector-formats/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ConnectorFormat 
     * @throws ApiError
     */
    public ocpiConnectorFormatsUpdate({
id,
data,
}: {
/**
 * A unique integer value identifying this connector format.
 */
id: number,
data: ConnectorFormat,
}): CancelablePromise<ConnectorFormat> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/ocpi/connector-formats/{id}/',
            path: {
                'id': id,
            },
            body: data,
        });
    }

    /**
     * @returns ConnectorFormat 
     * @throws ApiError
     */
    public ocpiConnectorFormatsPartialUpdate({
id,
data,
}: {
/**
 * A unique integer value identifying this connector format.
 */
id: number,
data: ConnectorFormat,
}): CancelablePromise<ConnectorFormat> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/ocpi/connector-formats/{id}/',
            path: {
                'id': id,
            },
            body: data,
        });
    }

    /**
     * @returns void 
     * @throws ApiError
     */
    public ocpiConnectorFormatsDelete({
id,
}: {
/**
 * A unique integer value identifying this connector format.
 */
id: number,
}): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/ocpi/connector-formats/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ConnectorPowerType 
     * @throws ApiError
     */
    public ocpiConnectorPowerTypesList(): CancelablePromise<Array<ConnectorPowerType>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ocpi/connector-power-types/',
        });
    }

    /**
     * @returns ConnectorPowerType 
     * @throws ApiError
     */
    public ocpiConnectorPowerTypesCreate({
data,
}: {
data: ConnectorPowerType,
}): CancelablePromise<ConnectorPowerType> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/ocpi/connector-power-types/',
            body: data,
        });
    }

    /**
     * @returns ConnectorPowerType 
     * @throws ApiError
     */
    public ocpiConnectorPowerTypesRead({
id,
}: {
/**
 * A unique integer value identifying this connector power type.
 */
id: number,
}): CancelablePromise<ConnectorPowerType> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ocpi/connector-power-types/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ConnectorPowerType 
     * @throws ApiError
     */
    public ocpiConnectorPowerTypesUpdate({
id,
data,
}: {
/**
 * A unique integer value identifying this connector power type.
 */
id: number,
data: ConnectorPowerType,
}): CancelablePromise<ConnectorPowerType> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/ocpi/connector-power-types/{id}/',
            path: {
                'id': id,
            },
            body: data,
        });
    }

    /**
     * @returns ConnectorPowerType 
     * @throws ApiError
     */
    public ocpiConnectorPowerTypesPartialUpdate({
id,
data,
}: {
/**
 * A unique integer value identifying this connector power type.
 */
id: number,
data: ConnectorPowerType,
}): CancelablePromise<ConnectorPowerType> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/ocpi/connector-power-types/{id}/',
            path: {
                'id': id,
            },
            body: data,
        });
    }

    /**
     * @returns void 
     * @throws ApiError
     */
    public ocpiConnectorPowerTypesDelete({
id,
}: {
/**
 * A unique integer value identifying this connector power type.
 */
id: number,
}): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/ocpi/connector-power-types/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ConnectorType 
     * @throws ApiError
     */
    public ocpiConnectorTypesList(): CancelablePromise<Array<ConnectorType>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ocpi/connector-types/',
        });
    }

    /**
     * @returns ConnectorType 
     * @throws ApiError
     */
    public ocpiConnectorTypesCreate({
data,
}: {
data: ConnectorType,
}): CancelablePromise<ConnectorType> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/ocpi/connector-types/',
            body: data,
        });
    }

    /**
     * @returns ConnectorType 
     * @throws ApiError
     */
    public ocpiConnectorTypesRead({
id,
}: {
/**
 * A unique integer value identifying this connector type.
 */
id: number,
}): CancelablePromise<ConnectorType> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ocpi/connector-types/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ConnectorType 
     * @throws ApiError
     */
    public ocpiConnectorTypesUpdate({
id,
data,
}: {
/**
 * A unique integer value identifying this connector type.
 */
id: number,
data: ConnectorType,
}): CancelablePromise<ConnectorType> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/ocpi/connector-types/{id}/',
            path: {
                'id': id,
            },
            body: data,
        });
    }

    /**
     * @returns ConnectorType 
     * @throws ApiError
     */
    public ocpiConnectorTypesPartialUpdate({
id,
data,
}: {
/**
 * A unique integer value identifying this connector type.
 */
id: number,
data: ConnectorType,
}): CancelablePromise<ConnectorType> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/ocpi/connector-types/{id}/',
            path: {
                'id': id,
            },
            body: data,
        });
    }

    /**
     * @returns void 
     * @throws ApiError
     */
    public ocpiConnectorTypesDelete({
id,
}: {
/**
 * A unique integer value identifying this connector type.
 */
id: number,
}): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/ocpi/connector-types/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public ocpiDomainValuesList({
tariffTypes,
connectorStandards,
connectorFormats,
connectorPowerTypes,
evseStatuses,
evseParkingRestrictions,
evseCapabilities,
}: {
/**
 * Include Tariff Type
 */
tariffTypes?: boolean,
/**
 * Include Connector Standard
 */
connectorStandards?: boolean,
/**
 * Include Connector Format
 */
connectorFormats?: boolean,
/**
 * Include Connector Power Type
 */
connectorPowerTypes?: boolean,
/**
 * Include EVSE Status
 */
evseStatuses?: boolean,
/**
 * Include EVSE Parking Restriction
 */
evseParkingRestrictions?: boolean,
/**
 * Include EVSE Capability
 */
evseCapabilities?: boolean,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ocpi/domain-values',
            query: {
                'tariff_types': tariffTypes,
                'connector_standards': connectorStandards,
                'connector_formats': connectorFormats,
                'connector_power_types': connectorPowerTypes,
                'evse_statuses': evseStatuses,
                'evse_parking_restrictions': evseParkingRestrictions,
                'evse_capabilities': evseCapabilities,
            },
        });
    }

    /**
     * @returns EVSECapability 
     * @throws ApiError
     */
    public ocpiEvseCapabilitiesList(): CancelablePromise<Array<EVSECapability>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ocpi/evse-capabilities/',
        });
    }

    /**
     * @returns EVSECapability 
     * @throws ApiError
     */
    public ocpiEvseCapabilitiesCreate({
data,
}: {
data: EVSECapability,
}): CancelablePromise<EVSECapability> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/ocpi/evse-capabilities/',
            body: data,
        });
    }

    /**
     * @returns EVSECapability 
     * @throws ApiError
     */
    public ocpiEvseCapabilitiesRead({
id,
}: {
/**
 * A unique integer value identifying this evse capability.
 */
id: number,
}): CancelablePromise<EVSECapability> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ocpi/evse-capabilities/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns EVSECapability 
     * @throws ApiError
     */
    public ocpiEvseCapabilitiesUpdate({
id,
data,
}: {
/**
 * A unique integer value identifying this evse capability.
 */
id: number,
data: EVSECapability,
}): CancelablePromise<EVSECapability> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/ocpi/evse-capabilities/{id}/',
            path: {
                'id': id,
            },
            body: data,
        });
    }

    /**
     * @returns EVSECapability 
     * @throws ApiError
     */
    public ocpiEvseCapabilitiesPartialUpdate({
id,
data,
}: {
/**
 * A unique integer value identifying this evse capability.
 */
id: number,
data: EVSECapability,
}): CancelablePromise<EVSECapability> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/ocpi/evse-capabilities/{id}/',
            path: {
                'id': id,
            },
            body: data,
        });
    }

    /**
     * @returns void 
     * @throws ApiError
     */
    public ocpiEvseCapabilitiesDelete({
id,
}: {
/**
 * A unique integer value identifying this evse capability.
 */
id: number,
}): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/ocpi/evse-capabilities/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns EVSEParkingRestriction 
     * @throws ApiError
     */
    public ocpiEvseParkingRestrictionsList(): CancelablePromise<Array<EVSEParkingRestriction>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ocpi/evse-parking-restrictions/',
        });
    }

    /**
     * @returns EVSEParkingRestriction 
     * @throws ApiError
     */
    public ocpiEvseParkingRestrictionsCreate({
data,
}: {
data: EVSEParkingRestriction,
}): CancelablePromise<EVSEParkingRestriction> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/ocpi/evse-parking-restrictions/',
            body: data,
        });
    }

    /**
     * @returns EVSEParkingRestriction 
     * @throws ApiError
     */
    public ocpiEvseParkingRestrictionsRead({
id,
}: {
/**
 * A unique integer value identifying this evse parking restriction.
 */
id: number,
}): CancelablePromise<EVSEParkingRestriction> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ocpi/evse-parking-restrictions/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns EVSEParkingRestriction 
     * @throws ApiError
     */
    public ocpiEvseParkingRestrictionsUpdate({
id,
data,
}: {
/**
 * A unique integer value identifying this evse parking restriction.
 */
id: number,
data: EVSEParkingRestriction,
}): CancelablePromise<EVSEParkingRestriction> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/ocpi/evse-parking-restrictions/{id}/',
            path: {
                'id': id,
            },
            body: data,
        });
    }

    /**
     * @returns EVSEParkingRestriction 
     * @throws ApiError
     */
    public ocpiEvseParkingRestrictionsPartialUpdate({
id,
data,
}: {
/**
 * A unique integer value identifying this evse parking restriction.
 */
id: number,
data: EVSEParkingRestriction,
}): CancelablePromise<EVSEParkingRestriction> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/ocpi/evse-parking-restrictions/{id}/',
            path: {
                'id': id,
            },
            body: data,
        });
    }

    /**
     * @returns void 
     * @throws ApiError
     */
    public ocpiEvseParkingRestrictionsDelete({
id,
}: {
/**
 * A unique integer value identifying this evse parking restriction.
 */
id: number,
}): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/ocpi/evse-parking-restrictions/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns EVSEStatus 
     * @throws ApiError
     */
    public ocpiEvseStatusList(): CancelablePromise<Array<EVSEStatus>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ocpi/evse-status/',
        });
    }

    /**
     * @returns EVSEStatus 
     * @throws ApiError
     */
    public ocpiEvseStatusCreate({
data,
}: {
data: EVSEStatus,
}): CancelablePromise<EVSEStatus> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/ocpi/evse-status/',
            body: data,
        });
    }

    /**
     * @returns EVSEStatus 
     * @throws ApiError
     */
    public ocpiEvseStatusRead({
id,
}: {
/**
 * A unique integer value identifying this evse status.
 */
id: number,
}): CancelablePromise<EVSEStatus> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ocpi/evse-status/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns EVSEStatus 
     * @throws ApiError
     */
    public ocpiEvseStatusUpdate({
id,
data,
}: {
/**
 * A unique integer value identifying this evse status.
 */
id: number,
data: EVSEStatus,
}): CancelablePromise<EVSEStatus> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/ocpi/evse-status/{id}/',
            path: {
                'id': id,
            },
            body: data,
        });
    }

    /**
     * @returns EVSEStatus 
     * @throws ApiError
     */
    public ocpiEvseStatusPartialUpdate({
id,
data,
}: {
/**
 * A unique integer value identifying this evse status.
 */
id: number,
data: EVSEStatus,
}): CancelablePromise<EVSEStatus> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/ocpi/evse-status/{id}/',
            path: {
                'id': id,
            },
            body: data,
        });
    }

    /**
     * @returns void 
     * @throws ApiError
     */
    public ocpiEvseStatusDelete({
id,
}: {
/**
 * A unique integer value identifying this evse status.
 */
id: number,
}): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/ocpi/evse-status/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Returns the ingestion log from the database, given the id of the ingestion.
     * @returns any OK
     * @throws ApiError
     */
    public ocpiIngestionLogsList({
id,
}: {
/**
 * Ingestion ID
 */
id: string,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ocpi/ingestion-logs',
            query: {
                'id': id,
            },
        });
    }

    /**
     * No args, returns the datetime of the last ingestion log
     * @returns any OK
     * @throws ApiError
     */
    public ocpiLastUpdateList(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ocpi/last-update',
        });
    }

    /**
     * @returns TariffType 
     * @throws ApiError
     */
    public ocpiTariffTypesList(): CancelablePromise<Array<TariffType>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ocpi/tariff-types/',
        });
    }

    /**
     * @returns TariffType 
     * @throws ApiError
     */
    public ocpiTariffTypesCreate({
data,
}: {
data: TariffType,
}): CancelablePromise<TariffType> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/ocpi/tariff-types/',
            body: data,
        });
    }

    /**
     * @returns TariffType 
     * @throws ApiError
     */
    public ocpiTariffTypesRead({
id,
}: {
/**
 * A unique integer value identifying this tariff type.
 */
id: number,
}): CancelablePromise<TariffType> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ocpi/tariff-types/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns TariffType 
     * @throws ApiError
     */
    public ocpiTariffTypesUpdate({
id,
data,
}: {
/**
 * A unique integer value identifying this tariff type.
 */
id: number,
data: TariffType,
}): CancelablePromise<TariffType> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/ocpi/tariff-types/{id}/',
            path: {
                'id': id,
            },
            body: data,
        });
    }

    /**
     * @returns TariffType 
     * @throws ApiError
     */
    public ocpiTariffTypesPartialUpdate({
id,
data,
}: {
/**
 * A unique integer value identifying this tariff type.
 */
id: number,
data: TariffType,
}): CancelablePromise<TariffType> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/ocpi/tariff-types/{id}/',
            path: {
                'id': id,
            },
            body: data,
        });
    }

    /**
     * @returns void 
     * @throws ApiError
     */
    public ocpiTariffTypesDelete({
id,
}: {
/**
 * A unique integer value identifying this tariff type.
 */
id: number,
}): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/ocpi/tariff-types/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
