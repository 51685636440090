import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import ChargingLogo from '@/assets/logo.webp'
import { RaaeyLogo } from '@/modules/core/components/RaaeyLogo'
import { BetaBanner } from '@/modules/core/components/VersionBanner'
import { VersionBanner } from './VersionBanner'

export function Footer() {
    const { t } = useTranslation()
    return (
        <>
            <footer className="footer py-10 container m-auto border-t  border-base-300">
                <div>
                    <span className="footer-title">{t('footer.app')}</span>
                    <Link className="link link-hover" to="about">
                        {t('footer.about')}
                    </Link>
                    <Link className="link link-hover" to="disclaimer">
                        {t('footer.disclaimer')}
                    </Link>
                </div>
                <div>
                    <span className="footer-title">
                        {t('footer.useful_links')}
                    </span>

                    <a
                        className="link link-hover"
                        href="https://www.rae.gr/"
                        target="_blank"
                    >
                        {t('footer.rae')}
                    </a>
                    <a
                        className="link link-hover"
                        href="https://electrokinisi.yme.gov.gr/public/Account/Login?ReturnUrl=%2Fpublic%2F"
                        target="_blank"
                    >
                        {t('footer.myfah')}
                    </a>
                </div>

                <div>
                    <span className="footer-title">{t('footer.legal')}</span>

                    {/* <a className="link link-hover">{t('footer.terms')}</a> */}
                    <a
                        className="link link-hover"
                        href="https://www.rae.gr/gdpr/"
                        target="_blank"
                    >
                        {t('footer.privacy_policy')}
                    </a>
                    {/* <a className="link link-hover">Cookie policy</a> */}
                </div>
            </footer>
            <footer className="footer py-4  container m-auto">
                <div className="items-center grid-flow-col">
                    <a
                        className="link link-hover"
                        href="https://www.rae.gr/"
                        target="_blank"
                        aria-label="RAAEY Website"
                    >
                        <RaaeyLogo className="w-24" />
                    </a>

                    <img
                        src={ChargingLogo}
                        alt="Charging Cost logo"
                        className="h-10"
                    />

                    <p>
                        ©RAAEY, 2024
                        <br /> All rights reserved
                    </p>
                    <div className="flex flex-col justify-end h-full p-1 m-1">
                        <VersionBanner />
                    </div>
                </div>
                <div className="md:place-self-center md:justify-self-end">
                    <div className="grid grid-flow-col gap-4">
                        <a
                            href="https://linkedin.com/company/regulatory-authority-for-energy-rae/"
                            target="_blank"
                            rel="chargingcost.gr"
                            className="opacity-70 hover:opacity-90"
                            aria-label="RAAEY LinkedIn"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                x="0px"
                                y="0px"
                                width="35"
                                height="35"
                                viewBox="0 0 50 50"
                                style={{ fill: '#152e73' }}
                            >
                                <path d="M41,4H9C6.24,4,4,6.24,4,9v32c0,2.76,2.24,5,5,5h32c2.76,0,5-2.24,5-5V9C46,6.24,43.76,4,41,4z M17,20v19h-6V20H17z M11,14.47c0-1.4,1.2-2.47,3-2.47s2.93,1.07,3,2.47c0,1.4-1.12,2.53-3,2.53C12.2,17,11,15.87,11,14.47z M39,39h-6c0,0,0-9.26,0-10 c0-2-1-4-3.5-4.04h-0.08C27,24.96,26,27.02,26,29c0,0.91,0,10,0,10h-6V20h6v2.56c0,0,1.93-2.56,5.81-2.56 c3.97,0,7.19,2.73,7.19,8.26V39z"></path>
                            </svg>
                        </a>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer
