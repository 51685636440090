import React, { useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import maplibregl from 'maplibre-gl'

import { useMap } from '@/modules/map'
import { RoutingContext } from '@/packages/routing/context'
import { DataContext } from '@/modules/core/context/DataContext'

import { InitDirectionsAPI } from '@/packages/routing/lib/directions-api'
import { DataLayer } from './DataLayer'
import { Toaster } from 'sonner'
import { UserPositionMarker } from '@/packages/position/components/MapMarker'

import MaplibreGeocoder from '@maplibre/maplibre-gl-geocoder'
import '@maplibre/maplibre-gl-geocoder/dist/maplibre-gl-geocoder.css'
import { geocoder } from '@/modules/core/lib/geocode'

import { mapSetStyleKeepLayers } from '@/modules/core/lib/utils'
import { getStyleJson } from '../config/style'

import 'maplibre-gl/dist/maplibre-gl.css'

maplibregl.prewarm()

const withAllContext = (WrappedComponent) => {
    const MemoizedComponent = React.memo(WrappedComponent)

    return function withAllContext(props) {
        const {
            currentMap,
            setCurrentMap,
            mapContainer,
            mapStyle,
            loading,
            setLoading,
        } = useMap()
        const {
            directions,
            setDirections,
            setTotalDistance,
            setTotalDuration,
        } = useContext(RoutingContext)
        const { selectedLocation } = useContext(DataContext)

        return (
            <MemoizedComponent
                {...props}
                currentMap={currentMap}
                setCurrentMap={setCurrentMap}
                mapContainer={mapContainer}
                mapStyle={mapStyle}
                loading={loading}
                setLoading={setLoading}
                directions={directions}
                setDirections={setDirections}
                setTotalDistance={setTotalDistance}
                setTotalDuration={setTotalDuration}
                selectedLocation={selectedLocation}
            />
        )
    }
}

const MAX_BOUNDS = [
    [18.545212627668036, 34.58600768874571], // Southwest coordinates (lng, lat)
    [31.25118933502796, 42.05269480189949], // Northeast coordinates (lng, lat)
]

function MapComponent({
    currentMap,
    setCurrentMap,
    mapContainer,
    mapStyle,
    loading,
    setLoading,
    directions,
    setDirections,
    setTotalDistance,
    setTotalDuration,
    selectedLocation,
    children,
    ...rest
}) {
    const { t } = useTranslation()

    const center = React.useMemo(() =>
        selectedLocation
            ? {
                  lng: selectedLocation.geometry.coordinates[0],
                  lat: selectedLocation.geometry.coordinates[1],
              }
            : { lng: 23.790886503543334, lat: 38.017083696843684 }
    )

    // Clean up map on unmount
    useEffect(() => {
        return () => {
            if (currentMap && currentMap instanceof maplibregl.Map) {
                try {
                    if (directions) directions.destroy()
                    currentMap.remove()
                } catch (e) {
                    setCurrentMap(undefined)
                }
            }
            // window.map = null
            setCurrentMap(undefined)
        }
    }, [])

    // Initialize Map
    useEffect(() => {
        if (currentMap) return
        const mapInstance = new maplibregl.Map({
            container: mapContainer.current,
            style: getStyleJson(mapStyle),
            center: [23.81, 38.33],
            zoom: 6,
            maxBounds: MAX_BOUNDS,
            // preserveDrawingBuffer: true,
            maxPitch: 50,
            hash: false,
            preferWebGL: true,
            attributionControl: false,
            ScaleControl: false,
            navigationControl: false,
        })

        mapInstance.addControl(
            new maplibregl.ScaleControl({
                maxWidth: 100,
                unit: 'metric',
            }),
            'bottom-left'
        )

        mapInstance.addControl(
            new maplibregl.AttributionControl({
                compact: true,
            }),
            'bottom-right'
        )

        mapInstance.addControl(
            new MaplibreGeocoder(geocoder, {
                maplibregl: maplibregl,
                collapsed: true,
                countries: 'el,gr',
                placeholder: t('Αναζήτηση τοποθεσίας'),
            }),
            'bottom-left'
        )

        mapInstance.on('idle', () => {
            setLoading(false)
        })

        // window.map = mapInstance
        setCurrentMap(mapInstance)

        // Jump to the selected location (useful when redirected from the dashboard)
        if (selectedLocation) {
            mapInstance.flyTo({
                center: {
                    lng: selectedLocation.geometry.coordinates[0],
                    lat: selectedLocation.geometry.coordinates[1],
                },
                zoom: mapInstance.getZoom() > 10 ? mapInstance.getZoom() : 10,
                speed: 0.8,
                curve: 1,
                easing(t) {
                    return t
                },
            })
        }
    }, [currentMap, center])

    // When map style changes, update the map
    // but keep the extra layers (directions, data, etc )
    useEffect(() => {
        if (!currentMap) return
        mapSetStyleKeepLayers(currentMap, getStyleJson(mapStyle))
    }, [mapStyle])

    // When selectedLocation changes, zoom to the new location
    useEffect(() => {
        if (!selectedLocation || !currentMap) return
        currentMap.flyTo({
            center: {
                lng: selectedLocation.geometry.coordinates[0],
                lat: selectedLocation.geometry.coordinates[1],
            },
            zoom: currentMap.getZoom() > 15 ? currentMap.getZoom() : 15,
            speed: 1,
            curve: 1,
            easing(t) {
                return t
            },
        })
    }, [selectedLocation])

    // Initialize Directions API
    useEffect(() => {
        if (loading || !currentMap) return

        if (!directions) {
            const directionsInstance = InitDirectionsAPI(currentMap, {
                loadingIndicator: true,
                setTotalDistance,
                setTotalDuration,
                apiOptions: {
                    showDist: true,
                },
            })
            setDirections(directionsInstance)
            window.directions = directionsInstance
            return
        }

        setDirections(directions)
        window.directions = directions

        return () => {
            // directions.destroy()
        }
    }, [loading])

    return (
        <>
            {loading && (
                <div className="relative">
                    <div className="absolute top-0 right-0 w-full h-screen flex flex-col items-center content-center">
                        Loading . . .
                    </div>
                </div>
            )}

            <div
                ref={mapContainer}
                className="h-full w-full shadow-inner"
                {...rest}
            >
                {children}

                {currentMap && (
                    <>
                        <DataLayer />
                        <UserPositionMarker />
                    </>
                )}
            </div>
            <Toaster />
        </>
    )
}

export default withAllContext(MapComponent)
