import React from 'react'
import { useContext } from 'react'
import { DataContext } from '@/modules/core/context/DataContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBolt } from '@fortawesome/free-solid-svg-icons'

interface FastChargeProps {
    title: string
    readonly parameter: 'fastCharge'
    size: 'xs' | 'sm' | 'md' | 'lg'
    onSidebar?: boolean
    disabled?: boolean
}

/**
 * A checkbox to filter the stations that have fast charge
 * @returns
 */
export function FastCharge({
    title,
    parameter = 'fastCharge',
    size = 'md',
    onSidebar = false,
    disabled = false,
}: FastChargeProps) {
    const data = useContext(DataContext)
    const parameterValue = data.params[parameter] // global state

    const handleChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
        data.setParam(parameter, e.target.checked ? true : false)
    }

    return (
        <div className="form-control flex flex-col justify-center">
            <label className={`cursor-pointer label ${!onSidebar && ' -'}`}>
                <span className={`text-${size} text-left`}>
                    <FontAwesomeIcon icon={faBolt} className="mr-1 text-xs" />{' '}
                    {title}
                </span>
                <input
                    className={`checkbox checkbox-secondary checkbox-${size} `}
                    type="checkbox"
                    id={parameter}
                    onChange={handleChanges}
                    disabled={disabled}
                    checked={parameterValue === true}
                />
            </label>
        </div>
    )
}

export default FastCharge
