/**
 * @file CookieConsent.jsx
 * @desc Component for cookie consent banner. Handles a binary choice: Accept All or Accept Necessary.
 * @see https://www.npmjs.com/package/react-cookie-consent
 * @see https://www.npmjs.com/package/react-ga4
 */
import React from 'react'
import { useTranslation, Trans } from 'react-i18next'
import CookieConsent, { Cookies } from 'react-cookie-consent'
import { useAnalytics } from '../analytics/analytics-context'
import moment from 'moment'
import CookieDetails from './CookieDetails'
import TabsComponent from '@/modules/core/components/TabsComponent'

import './overwrites.css'

// MUST be prefixed with VITE_
// see: https://vitejs.dev/guide/env-variables.html
const ANALYTICS_ID = import.meta.env.VITE_GOOGLE_ANALYTICS_ID
const ANALYTICS_COOKIE_ID = '_ga_' + ANALYTICS_ID.replace('G-', '')

const CONSENT_COOKIE = import.meta.env.VITE_CONSENT_COOKIE_NAME

function removeGoogleAnalyticsCookies() {
    Cookies.remove('_ga')
    Cookies.remove('_gat')
    Cookies.remove('_gid')
    Cookies.remove(ANALYTICS_COOKIE_ID)
}

function hasDeclaredConsent() {
    return (
        Cookies.get(CONSENT_COOKIE) === 'all' ||
        Cookies.get(CONSENT_COOKIE) === 'necessary'
    )
}

export default function AppCookieConsent() {
    const { t } = useTranslation()
    const { setConsent } = useAnalytics()
    const [visible, setVisible] = React.useState(!hasDeclaredConsent())

    // Set cookie expiration date to 1 year, or VITE_CONSENT_COOKIE_EXPIRES_DAYS if defined
    const cookieExpires = moment()
    if (import.meta.env.VITE_CONSENT_COOKIE_EXPIRES_DAYS) {
        cookieExpires.add(
            import.meta.env.VITE_CONSENT_COOKIE_EXPIRES_DAYS,
            'days'
        )
    }

    const handleDeclineOptional = () => {
        setConsent(false)
        setVisible(false)
        // Remove Google Analytics cookies
        removeGoogleAnalyticsCookies()
    }

    const handleAcceptOptional = () => {
        setConsent(true)
        setVisible(false)
    }

    const ConsentText = () => (
        <p>
            <Trans
                i18nKey="cookie_consent.text"
                // components={{
                //     cookie_policy_link: (
                //         // <a
                //         //     className="text-blue-500 dark:text-blue-200"
                //         //     target="_blank"
                //         //     href="https://www.rae.gr/en/gdpr-personal-data/"
                //         // />
                //         <></>
                //     ),
                // }}
            />
        </p>
    )

    return (
        <>
            <CookieConsent
                visible={visible ? 'show' : 'hidden'}
                location="bottom"
                buttonWrapperClasses="flex flex-col lg:flex-row gap-1 justify-evenly w-full p-4"
                enableDeclineButton
                cookieName={import.meta.env.VITE_CONSENT_COOKIE_NAME}
                expires={cookieExpires.toDate()}
                extraCookieOptions={{
                    sameSite: 'strict',
                    secure: true,
                }}
                // Accept optional
                cookieValue={'all'}
                buttonText={t('cookie_consent.consent_button')}
                buttonStyle={{ border: '2px', borderStyle: 'solid' }}
                onAccept={handleAcceptOptional}
                // Decline optional
                declineCookieValue="necessary"
                declineButtonText={t('cookie_consent.decline_button')}
                declineButtonStyle={{ border: '2px', borderStyle: 'solid' }}
                onDecline={handleDeclineOptional}
            >
                <h2 className=" mb-1 pb-1 dark:border-gray-600">
                    {t('cookie_consent.title')}
                </h2>
                <TabsComponent
                    tabContentClass="p-4 max-h-[70vh] overflow-y-auto"
                    child_tabs={[
                        {
                            title: t('cookie_consent.consent'),
                            content: <ConsentText />,
                        },
                        {
                            title: t('cookie_consent.details'),
                            content: <CookieDetails />,
                        },
                    ]}
                />
            </CookieConsent>
            {!visible ? (
                <button
                    onClick={() => setVisible(true)}
                    className="fixed z-40 bottom-20 md:bottom-2 right-2 text-2xl grayscale hover:grayscale-0"
                >
                    🍪
                </button>
            ) : null}
        </>
    )
}
