/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Company } from '../models/Company';
import type { CustomUser } from '../models/CustomUser';
import type { Login } from '../models/Login';
import type { PasswordChange } from '../models/PasswordChange';
import type { PasswordReset } from '../models/PasswordReset';
import type { PasswordResetConfirm } from '../models/PasswordResetConfirm';
import type { Register } from '../models/Register';
import type { ResendEmailVerification } from '../models/ResendEmailVerification';
import type { SocialLogin } from '../models/SocialLogin';
import type { UserDetails } from '../models/UserDetails';
import type { VerifyEmail } from '../models/VerifyEmail';
import type { WhoAmI } from '../models/WhoAmI';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AuthService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns Company 
     * @throws ApiError
     */
    public authCompaniesList(): CancelablePromise<Array<Company>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/companies/',
        });
    }

    /**
     * @returns Company 
     * @throws ApiError
     */
    public authCompaniesCreate({
data,
}: {
data: Company,
}): CancelablePromise<Company> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/companies/',
            body: data,
        });
    }

    /**
     * @returns Company 
     * @throws ApiError
     */
    public authCompaniesRead({
id,
}: {
/**
 * A unique integer value identifying this Εταιρεία.
 */
id: number,
}): CancelablePromise<Company> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/companies/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Company 
     * @throws ApiError
     */
    public authCompaniesUpdate({
id,
data,
}: {
/**
 * A unique integer value identifying this Εταιρεία.
 */
id: number,
data: Company,
}): CancelablePromise<Company> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/auth/companies/{id}/',
            path: {
                'id': id,
            },
            body: data,
        });
    }

    /**
     * @returns Company 
     * @throws ApiError
     */
    public authCompaniesPartialUpdate({
id,
data,
}: {
/**
 * A unique integer value identifying this Εταιρεία.
 */
id: number,
data: Company,
}): CancelablePromise<Company> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/auth/companies/{id}/',
            path: {
                'id': id,
            },
            body: data,
        });
    }

    /**
     * @returns void 
     * @throws ApiError
     */
    public authCompaniesDelete({
id,
}: {
/**
 * A unique integer value identifying this Εταιρεία.
 */
id: number,
}): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/auth/companies/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns any 
     * @throws ApiError
     */
    public authCsrfList(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/csrf/',
        });
    }

    /**
     * @returns SocialLogin 
     * @throws ApiError
     */
    public authGoogleLoginCreate({
data,
}: {
data: SocialLogin,
}): CancelablePromise<SocialLogin> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/google-login/',
            body: data,
        });
    }

    /**
     * Check the credentials and return the REST Token
 * if the credentials are valid and authenticated.
 * Calls Django Auth login method to register User ID
 * in Django session framework
 *
 * Accept the following POST parameters: username, password
 * Return the REST Framework Token Object's key.
     * @returns Login 
     * @throws ApiError
     */
    public authLoginCreate({
data,
}: {
data: Login,
}): CancelablePromise<Login> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/login/',
            body: data,
        });
    }

    /**
     * Calls Django logout method and delete the Token object
 * assigned to the current User object.
     * Accepts/Returns nothing.
     * @returns any 
     * @throws ApiError
     */
    public authLogoutList(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/logout/',
        });
    }

    /**
     * Calls Django logout method and delete the Token object
 * assigned to the current User object.
     * Accepts/Returns nothing.
     * @returns any 
     * @throws ApiError
     */
    public authLogoutCreate(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/logout/',
        });
    }

    /**
     * Redirect to frontend, using uid & token
     * @returns any 
     * @throws ApiError
     */
    public authPasswordResetConfirmRead({
uidb64,
token,
}: {
uidb64: string,
token: string,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/password-reset/confirm/{uidb64}/{token}/',
            path: {
                'uidb64': uidb64,
                'token': token,
            },
        });
    }

    /**
     * Calls Django Auth SetPasswordForm save method.
     * Accepts the following POST parameters: new_password1, new_password2
 * Returns the success/fail message.
     * @returns PasswordChange 
     * @throws ApiError
     */
    public authPasswordChangeCreate({
data,
}: {
data: PasswordChange,
}): CancelablePromise<PasswordChange> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/password/change/',
            body: data,
        });
    }

    /**
     * Calls Django Auth PasswordResetForm save method.
     * Accepts the following POST parameters: email
 * Returns the success/fail message.
     * @returns PasswordReset 
     * @throws ApiError
     */
    public authPasswordResetCreate({
data,
}: {
data: PasswordReset,
}): CancelablePromise<PasswordReset> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/password/reset/',
            body: data,
        });
    }

    /**
     * Password reset e-mail link is confirmed, therefore
 * this resets the user's password.
     * Accepts the following POST parameters: token, uid,
 * new_password1, new_password2
 * Returns the success/fail message.
     * @returns PasswordResetConfirm 
     * @throws ApiError
     */
    public authPasswordResetConfirmCreate({
data,
}: {
data: PasswordResetConfirm,
}): CancelablePromise<PasswordResetConfirm> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/password/reset/confirm/',
            body: data,
        });
    }

    /**
     * @returns Register 
     * @throws ApiError
     */
    public authRegistrationCreate({
data,
}: {
data: Register,
}): CancelablePromise<Register> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/registration/',
            body: data,
        });
    }

    /**
     * Overriding dj_rest_auth ConfirmEmailView (`dj_rest_auth.registration.views.VerifyEmailView`)
 * to parse the token from the request url instead of the request body.
     * @returns VerifyEmail 
     * @throws ApiError
     */
    public authRegistrationAccountConfirmEmailRead({
key,
}: {
key: string,
}): CancelablePromise<VerifyEmail> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/registration/account-confirm-email/{key}/',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @returns ResendEmailVerification 
     * @throws ApiError
     */
    public authRegistrationResendEmailCreate({
data,
}: {
data: ResendEmailVerification,
}): CancelablePromise<ResendEmailVerification> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/registration/resend-email/',
            body: data,
        });
    }

    /**
     * @returns VerifyEmail 
     * @throws ApiError
     */
    public authRegistrationVerifyEmailCreate({
data,
}: {
data: VerifyEmail,
}): CancelablePromise<VerifyEmail> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/registration/verify-email/',
            body: data,
        });
    }

    /**
     * Delete existing user token and create a new one
     * @returns any 
     * @throws ApiError
     */
    public authResendTokenList(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/resend-token/',
        });
    }

    /**
     * @returns any 
     * @throws ApiError
     */
    public authSessionList(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/session/',
        });
    }

    /**
     * @returns void 
     * @throws ApiError
     */
    public authTerminateAccountDelete(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/auth/terminate-account/',
        });
    }

    /**
     * @returns any 
     * @throws ApiError
     */
    public authUserProfileList(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/user-profile/',
        });
    }

    /**
     * @returns any 
     * @throws ApiError
     */
    public authUserProfileUpdate(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/auth/user-profile/',
        });
    }

    /**
     * @returns void 
     * @throws ApiError
     */
    public authUserProfileDelete(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/auth/user-profile/',
        });
    }

    /**
     * Reads and updates UserModel fields
 * Accepts GET, PUT, PATCH methods.
     * Default accepted fields: username, first_name, last_name
 * Default display fields: pk, username, email, first_name, last_name
 * Read-only fields: pk, email
 *
 * Returns UserModel fields.
     * @returns UserDetails 
     * @throws ApiError
     */
    public authUserRead(): CancelablePromise<UserDetails> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/user/',
        });
    }

    /**
     * Reads and updates UserModel fields
 * Accepts GET, PUT, PATCH methods.
     * Default accepted fields: username, first_name, last_name
 * Default display fields: pk, username, email, first_name, last_name
 * Read-only fields: pk, email
 *
 * Returns UserModel fields.
     * @returns UserDetails 
     * @throws ApiError
     */
    public authUserUpdate({
data,
}: {
data: UserDetails,
}): CancelablePromise<UserDetails> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/auth/user/',
            body: data,
        });
    }

    /**
     * Reads and updates UserModel fields
 * Accepts GET, PUT, PATCH methods.
     * Default accepted fields: username, first_name, last_name
 * Default display fields: pk, username, email, first_name, last_name
 * Read-only fields: pk, email
 *
 * Returns UserModel fields.
     * @returns UserDetails 
     * @throws ApiError
     */
    public authUserPartialUpdate({
data,
}: {
data: UserDetails,
}): CancelablePromise<UserDetails> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/auth/user/',
            body: data,
        });
    }

    /**
     * @returns CustomUser 
     * @throws ApiError
     */
    public authUsersList(): CancelablePromise<Array<CustomUser>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/users/',
        });
    }

    /**
     * @returns CustomUser 
     * @throws ApiError
     */
    public authUsersCreate({
data,
}: {
data: CustomUser,
}): CancelablePromise<CustomUser> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/users/',
            body: data,
        });
    }

    /**
     * @returns CustomUser 
     * @throws ApiError
     */
    public authUsersRead({
id,
}: {
/**
 * A unique integer value identifying this Χρήστης.
 */
id: number,
}): CancelablePromise<CustomUser> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/users/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns CustomUser 
     * @throws ApiError
     */
    public authUsersUpdate({
id,
data,
}: {
/**
 * A unique integer value identifying this Χρήστης.
 */
id: number,
data: CustomUser,
}): CancelablePromise<CustomUser> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/auth/users/{id}/',
            path: {
                'id': id,
            },
            body: data,
        });
    }

    /**
     * @returns CustomUser 
     * @throws ApiError
     */
    public authUsersPartialUpdate({
id,
data,
}: {
/**
 * A unique integer value identifying this Χρήστης.
 */
id: number,
data: CustomUser,
}): CancelablePromise<CustomUser> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/auth/users/{id}/',
            path: {
                'id': id,
            },
            body: data,
        });
    }

    /**
     * @returns void 
     * @throws ApiError
     */
    public authUsersDelete({
id,
}: {
/**
 * A unique integer value identifying this Χρήστης.
 */
id: number,
}): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/auth/users/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns WhoAmI 
     * @throws ApiError
     */
    public authWhoamiList(): CancelablePromise<WhoAmI> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/whoami/',
        });
    }

}
