// ref: https://maplibre.org/maplibre-gl-directions/#/examples/distance-measurement
// source: https://github.com/maplibre/maplibre-gl-directions/blob/main/demo/src/assets/map/distance-measurement-directions.ts

import type maplibregl from 'maplibre-gl'
import type {
    MapLibreGlDirectionsConfiguration,
    Route,
    Feature,
    Point,
    LineString,
} from '@maplibre/maplibre-gl-directions'
import MapLibreGlDirections, {
    layersFactory,
} from '@maplibre/maplibre-gl-directions'
import { utils } from '@maplibre/maplibre-gl-directions'
// import { MapLibreGlDirectionsOptions } from '@maplibre/maplibre-gl-directions/dist/maplibre-gl-directions'

export default class DistanceMeasurementMapLibreGlDirections extends MapLibreGlDirections {
    constructor(
        map: maplibregl.Map,
        configuration?: Partial<MapLibreGlDirectionsConfiguration>
    ) {
        super(map, configuration)
    }

    // here we save the original method to be able to use it in the re-defined one. For some methods (namely those
    // that are defined as methods and not as properties) you can instead call their "super" counterparts, but for the
    // methods as `buildRoutelines` it's impossible due to restrictions implied by the language itself, so that's the
    // only reasonable way to be able to use the original functionality as a part of the re-defined method
    originalBuildRoutelines = utils.buildRoutelines

    // re-defining the original `buildRoutelines` method
    protected buildRoutelines = (
        requestOptions: MapLibreGlDirectionsConfiguration['requestOptions'],
        routes: Route[],
        selectedRouteIndex: number,
        snappoints: Feature<Point>[]
    ): Feature<LineString>[][] => {
        // first we call the original method. It returns the built routelines
        const routelines = this.originalBuildRoutelines(
            requestOptions,
            routes,
            selectedRouteIndex,
            snappoints
        )

        // then we modify the routelines adding to each route leg a property that stores the leg's distance, in Km
        routelines[0].forEach((leg, index) => {
            if (leg.properties) {
                leg.properties.distance = Math.round(
                    (routes[0].legs[index].distance as number) / 1000
                ) as number
                leg.properties.duration = routes[0].legs[index]
                    .duration as number
            }
        })

        // and returning the modified routelines
        return routelines
    }
}

// using a different source name. That might become useful if you'd like to use the Distance Measurement Directions
// instance along with a normal Directions instance on the same map
const sourceName = 'maplibre-gl-directions' //'distance-measurement-maplibre-gl-directions'
const t = 1
const i = 1

const config: Partial<MapLibreGlDirectionsConfiguration> = {
    sourceName,
    layers: [
        {
            id: `${sourceName}-snapline`,
            type: 'line',
            source: sourceName,
            layout: {
                'line-cap': 'round',
                'line-join': 'round',
            },
            paint: {
                'line-dasharray': [3, 3],
                'line-color': '#34343f',
                'line-opacity': 0.65,
                'line-width': 3,
            },
            filter: ['==', ['get', 'type'], 'SNAPLINE'],
        },
        {
            id: `${sourceName}-alt-routeline-casing`,
            type: 'line',
            source: sourceName,
            layout: {
                'line-cap': 'butt',
                'line-join': 'round',
            },
            paint: {
                'line-color': '#9e91be',
                'line-opacity': 0.55,
                'line-width': [
                    'interpolate',
                    ['exponential', 1.5],
                    ['zoom'],
                    // on zoom levels 0-5 - 7px by default and 10px when highlighted
                    0,
                    // highlighted to default ratio (epsilon) = 10 / 7 ~= 1.42
                    [
                        'case',
                        ['boolean', ['get', 'highlight'], !1],
                        10 * i,
                        7 * i,
                    ],
                    5,
                    [
                        'case',
                        ['boolean', ['get', 'highlight'], !1],
                        10 * i,
                        7 * i,
                    ],
                    // exponentially grows on zoom levels 5-18 finally becoming 32px when highlighted
                    18,
                    // default = 32 / epsilon ~= 23
                    [
                        'case',
                        ['boolean', ['get', 'highlight'], !1],
                        32 * i,
                        23 * i,
                    ],
                ],
            },
            filter: ['==', ['get', 'route'], 'ALT'],
        },
        {
            id: `${sourceName}-alt-routeline`,
            type: 'line',
            source: sourceName,
            layout: {
                'line-cap': 'butt',
                'line-join': 'round',
            },
            paint: {
                'line-color': '#9e91be',
                'line-opacity': 0.85,
                'line-width': [
                    'interpolate',
                    ['exponential', 1.5],
                    ['zoom'],
                    // on zoom levels 0-5 - 4px smaller than the casing (2px on each side). 7 - 4 = 3.
                    // Doesn't change when highlighted
                    0,
                    // feature to casing ratio (psi) = 3 / 7 ~= 0.42
                    3 * i,
                    5,
                    3 * i,
                    // exponentially grows on zoom levels 5-18 finally becoming psi times the casing
                    18,
                    // psi * 23  ~= 10
                    10 * i,
                ],
            },
            filter: ['==', ['get', 'route'], 'ALT'],
        },
        {
            id: `${sourceName}-routeline-casing`,
            type: 'line',
            source: sourceName,
            layout: {
                'line-cap': 'butt',
                'line-join': 'round',
            },
            paint: {
                'line-color': [
                    'interpolate-hcl',
                    ['linear'],
                    ['get', 'congestion'],
                    0,
                    '#243788',
                    1,
                    '#42c74c',
                    100,
                    '#d72359',
                ],
                'line-opacity': 0.55,
                'line-width': [
                    // same as the alt-routeline-casing
                    'interpolate',
                    ['exponential', 1.5],
                    ['zoom'],
                    0,
                    [
                        'case',
                        ['boolean', ['get', 'highlight'], !1],
                        10 * i,
                        7 * i,
                    ],
                    5,
                    [
                        'case',
                        ['boolean', ['get', 'highlight'], !1],
                        10 * i,
                        7 * i,
                    ],
                    18,
                    [
                        'case',
                        ['boolean', ['get', 'highlight'], !1],
                        32 * i,
                        23 * i,
                    ],
                ],
            },
            filter: ['==', ['get', 'route'], 'SELECTED'],
        },
        {
            id: `${sourceName}-routeline`,
            type: 'line',
            source: sourceName,
            layout: {
                'line-cap': 'butt',
                'line-join': 'round',
            },
            paint: {
                'line-color': [
                    'interpolate-hcl',
                    ['linear'],
                    ['get', 'congestion'],
                    0,
                    '#153ce0',
                    1,
                    '#42c74c',
                    100,
                    '#d72359',
                ],
                'line-opacity': 0.85,
                'line-width': [
                    // same as alt-routeline
                    'interpolate',
                    ['exponential', 1.5],
                    ['zoom'],
                    0,
                    3 * i,
                    5,
                    3 * i,
                    18,
                    10 * i,
                ],
            },
            filter: ['==', ['get', 'route'], 'SELECTED'],
        },
        {
            id: `${sourceName}-hoverpoint-casing`,
            type: 'circle',
            source: sourceName,
            paint: {
                'circle-radius': [
                    // same as snappoint-casing, but without highlighting (since it's always highlighted while present on the map)
                    'interpolate',
                    ['exponential', 1.5],
                    ['zoom'],
                    0,
                    14 * t,
                    5,
                    14 * t,
                    18,
                    33 * t,
                ],
                'circle-color': '#30a856',
                'circle-opacity': 0.65,
            },
            filter: ['==', ['get', 'type'], 'HOVERPOINT'],
        },
        {
            id: `${sourceName}-hoverpoint`,
            type: 'circle',
            source: sourceName,
            paint: {
                'circle-radius': [
                    // same as snappoint, but without highlighting (since it's always highlighted while present on the map)
                    'interpolate',
                    ['exponential', 1.5],
                    ['zoom'],
                    0,
                    9 * t,
                    5,
                    9 * t,
                    18,
                    21 * t,
                ],
                'circle-color': '#30a856',
            },
            filter: ['==', ['get', 'type'], 'HOVERPOINT'],
        },
        {
            id: `${sourceName}-snappoint-casing`,
            type: 'circle',
            source: sourceName,
            minzoom: 10,
            paint: {
                'circle-radius': [
                    'interpolate',
                    ['exponential', 1.5],
                    ['zoom'],
                    // don't forget it's the radius! The visible value is diameter (which is 2x)
                    // on zoom levels 0-5 should be 5px more than the routeline casing. 7 + 5 = 12.
                    // When highlighted should be +2px more. 12 + 2 = 14
                    0,
                    // highlighted to default ratio (epsilon) = 14 / 12 ~= 1.16
                    [
                        'case',
                        ['boolean', ['get', 'highlight'], !1],
                        14 * t,
                        12 * t,
                    ],
                    5,
                    [
                        'case',
                        ['boolean', ['get', 'highlight'], !1],
                        14 * t,
                        12 * t,
                    ],
                    // exponentially grows on zoom levels 5-18 finally becoming the same 5px wider than the routeline's casing on
                    // the same zoom level: 23 + 5 = 28px
                    18,
                    // highlighted = default ~= 33
                    [
                        'case',
                        ['boolean', ['get', 'highlight'], !1],
                        33 * t,
                        28 * t,
                    ],
                ],
                'circle-color': [
                    'case',
                    ['boolean', ['get', 'highlight'], !1],
                    '#e50d3f',
                    '#cb3373',
                ],
                'circle-opacity': 0.65,
            },
            filter: ['==', ['get', 'type'], 'SNAPPOINT'],
        },
        {
            id: `${sourceName}-snappoint`,
            type: 'circle',
            source: sourceName,
            paint: {
                'circle-radius': [
                    'interpolate',
                    ['exponential', 1.5],
                    ['zoom'],
                    // on zoom levels 0-5 - 5px smaller than the casing. 12 - 5 = 7.
                    0,
                    // feature to casing ratio (psi) = 7 / 12 ~= 0.58
                    // highlighted to default ratio (epsilon) = 9 / 7 ~= 1.28
                    [
                        'case',
                        ['boolean', ['get', 'highlight'], !1],
                        9 * t,
                        7 * t,
                    ],
                    5,
                    [
                        'case',
                        ['boolean', ['get', 'highlight'], !1],
                        9 * t,
                        7 * t,
                    ],
                    // exponentially grows on zoom levels 5-18 finally becoming psi times the casing
                    18,
                    // psi * 28 ~= 16
                    // when highlighted multiply by epsilon ~= 21
                    [
                        'case',
                        ['boolean', ['get', 'highlight'], !1],
                        21 * t,
                        16 * t,
                    ],
                ],
                'circle-color': [
                    'case',
                    ['boolean', ['get', 'highlight'], !1],
                    '#e50d3f',
                    '#cb3373',
                ],
            },
            filter: ['==', ['get', 'type'], 'SNAPPOINT'],
        },
        {
            id: `${sourceName}-waypoint-casing`,
            type: 'circle',
            source: sourceName,
            paint: {
                'circle-radius': [
                    // same as snappoint-casing
                    'interpolate',
                    ['exponential', 1.5],
                    ['zoom'],
                    0,
                    [
                        'case',
                        ['boolean', ['get', 'highlight'], !1],
                        14 * t,
                        12 * t,
                    ],
                    5,
                    [
                        'case',
                        ['boolean', ['get', 'highlight'], !1],
                        14 * t,
                        12 * t,
                    ],
                    18,
                    [
                        'case',
                        ['boolean', ['get', 'highlight'], !1],
                        33 * t,
                        28 * t,
                    ],
                ],
                'circle-color': [
                    'case',
                    ['boolean', ['get', 'highlight'], !1],
                    '#153ce0',
                    '#153c90',
                ],
                'circle-opacity': 0.65,
            },
            filter: ['==', ['get', 'type'], 'WAYPOINT'],
        },
        {
            id: `${sourceName}-waypoint`,
            type: 'circle',
            source: sourceName,
            paint: {
                // same as snappoint
                'circle-radius': [
                    'interpolate',
                    ['exponential', 1.5],
                    ['zoom'],
                    0,
                    [
                        'case',
                        ['boolean', ['get', 'highlight'], !1],
                        9 * t,
                        7 * t,
                    ],
                    5,
                    [
                        'case',
                        ['boolean', ['get', 'highlight'], !1],
                        9 * t,
                        7 * t,
                    ],
                    18,
                    [
                        'case',
                        ['boolean', ['get', 'highlight'], !1],
                        21 * t,
                        16 * t,
                    ],
                ],
                'circle-color': [
                    'case',
                    ['boolean', ['get', 'highlight'], !1],
                    '#153ce0',
                    '#153c90',
                ],
            },
            filter: ['==', ['get', 'type'], 'WAYPOINT'],
        },
        {
            id: `${sourceName}-routeline-distance`,
            type: 'symbol',
            source: sourceName,
            layout: {
                'symbol-placement': 'line-center',
                'text-field': '{distance} Km',
                'text-font': ['Noto Sans Bold'],
                'text-size': 11,
                'text-ignore-placement': true,
                'text-allow-overlap': true,
                'text-overlap': 'always',
            },
            paint: {
                'text-color': '#ffffff',
                'text-halo-width': 1,
            },
            filter: ['==', ['get', 'route'], 'SELECTED'],
        },
    ] as maplibregl.LayerSpecification[],
    // don't forget to update the sensitive layers
    sensitiveSnappointLayers: [`${sourceName}-snappoint`],
    sensitiveWaypointLayers: [`${sourceName}-waypoint`],
    sensitiveRoutelineLayers: [`${sourceName}-routeline`],
    sensitiveAltRoutelineLayers: [],
}

export { config }
