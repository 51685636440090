/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Company } from './Company';

export type LocationChargingCost = {
    readonly id?: number;
    readonly evses?: string;
    company: Company;
    readonly opening_times?: string;
    readonly parking_type?: string;
    location_id?: string;
    country_code?: string;
    party_id: string;
    uid: string;
    publish?: boolean;
    name: string;
    address?: string | null;
    city?: string | null;
    postal_code?: string | null;
    state?: string | null;
    country?: LocationChargingCost.country;
    coordinates?: string | null;
    operator: string;
    suboperator: string;
    owner: string;
    charging_when_closed?: boolean;
    images?: any;
    time_zone: string;
    last_updated: string;
    /**
     * Αν η τοποθεσία έχει αποσυρθεί από τον Πάροχο (απουσιάζει από την τελευταία ενημέρωση του ΜΗΦΑΥ)
     */
    deprecated?: boolean;
    /**
     * Οδηγίες/Σχόλια
     */
    directions?: Array<number | null>;
};

export namespace LocationChargingCost {

    export enum country {
        ABW = 'ABW',
        AFG = 'AFG',
        AGO = 'AGO',
        AIA = 'AIA',
        ALA = 'ALA',
        ALB = 'ALB',
        AND = 'AND',
        ARE = 'ARE',
        ARG = 'ARG',
        ARM = 'ARM',
        ASM = 'ASM',
        ATA = 'ATA',
        ATF = 'ATF',
        ATG = 'ATG',
        AUS = 'AUS',
        AUT = 'AUT',
        AZE = 'AZE',
        BDI = 'BDI',
        BEL = 'BEL',
        BEN = 'BEN',
        BES = 'BES',
        BFA = 'BFA',
        BGD = 'BGD',
        BGR = 'BGR',
        BHR = 'BHR',
        BHS = 'BHS',
        BIH = 'BIH',
        BLM = 'BLM',
        BLR = 'BLR',
        BLZ = 'BLZ',
        BMU = 'BMU',
        BOL = 'BOL',
        BRA = 'BRA',
        BRB = 'BRB',
        BRN = 'BRN',
        BTN = 'BTN',
        BVT = 'BVT',
        BWA = 'BWA',
        CAF = 'CAF',
        CAN = 'CAN',
        CCK = 'CCK',
        CHE = 'CHE',
        CHL = 'CHL',
        CHN = 'CHN',
        CIV = 'CIV',
        CMR = 'CMR',
        COD = 'COD',
        COG = 'COG',
        COK = 'COK',
        COL = 'COL',
        COM = 'COM',
        CPV = 'CPV',
        CRI = 'CRI',
        CUB = 'CUB',
        CUW = 'CUW',
        CXR = 'CXR',
        CYM = 'CYM',
        CYP = 'CYP',
        CZE = 'CZE',
        DEU = 'DEU',
        DJI = 'DJI',
        DMA = 'DMA',
        DNK = 'DNK',
        DOM = 'DOM',
        DZA = 'DZA',
        ECU = 'ECU',
        EGY = 'EGY',
        ERI = 'ERI',
        ESH = 'ESH',
        ESP = 'ESP',
        EST = 'EST',
        ETH = 'ETH',
        FIN = 'FIN',
        FJI = 'FJI',
        FLK = 'FLK',
        FRA = 'FRA',
        FRO = 'FRO',
        FSM = 'FSM',
        GAB = 'GAB',
        GBR = 'GBR',
        GEO = 'GEO',
        GGY = 'GGY',
        GHA = 'GHA',
        GIB = 'GIB',
        GIN = 'GIN',
        GLP = 'GLP',
        GMB = 'GMB',
        GNB = 'GNB',
        GNQ = 'GNQ',
        GRC = 'GRC',
        GRD = 'GRD',
        GRL = 'GRL',
        GTM = 'GTM',
        GUF = 'GUF',
        GUM = 'GUM',
        GUY = 'GUY',
        HKG = 'HKG',
        HMD = 'HMD',
        HND = 'HND',
        HRV = 'HRV',
        HTI = 'HTI',
        HUN = 'HUN',
        IDN = 'IDN',
        IMN = 'IMN',
        IND = 'IND',
        IOT = 'IOT',
        IRL = 'IRL',
        IRN = 'IRN',
        IRQ = 'IRQ',
        ISL = 'ISL',
        ISR = 'ISR',
        ITA = 'ITA',
        JAM = 'JAM',
        JEY = 'JEY',
        JOR = 'JOR',
        JPN = 'JPN',
        KAZ = 'KAZ',
        KEN = 'KEN',
        KGZ = 'KGZ',
        KHM = 'KHM',
        KIR = 'KIR',
        KNA = 'KNA',
        KOR = 'KOR',
        KWT = 'KWT',
        LAO = 'LAO',
        LBN = 'LBN',
        LBR = 'LBR',
        LBY = 'LBY',
        LCA = 'LCA',
        LIE = 'LIE',
        LKA = 'LKA',
        LSO = 'LSO',
        LTU = 'LTU',
        LUX = 'LUX',
        LVA = 'LVA',
        MAC = 'MAC',
        MAF = 'MAF',
        MAR = 'MAR',
        MCO = 'MCO',
        MDA = 'MDA',
        MDG = 'MDG',
        MDV = 'MDV',
        MEX = 'MEX',
        MHL = 'MHL',
        MKD = 'MKD',
        MLI = 'MLI',
        MLT = 'MLT',
        MMR = 'MMR',
        MNE = 'MNE',
        MNG = 'MNG',
        MNP = 'MNP',
        MOZ = 'MOZ',
        MRT = 'MRT',
        MSR = 'MSR',
        MTQ = 'MTQ',
        MUS = 'MUS',
        MWI = 'MWI',
        MYS = 'MYS',
        MYT = 'MYT',
        NAM = 'NAM',
        NCL = 'NCL',
        NER = 'NER',
        NFK = 'NFK',
        NGA = 'NGA',
        NIC = 'NIC',
        NIU = 'NIU',
        NLD = 'NLD',
        NOR = 'NOR',
        NPL = 'NPL',
        NRU = 'NRU',
        NZL = 'NZL',
        OMN = 'OMN',
        PAK = 'PAK',
        PAN = 'PAN',
        PCN = 'PCN',
        PER = 'PER',
        PHL = 'PHL',
        PLW = 'PLW',
        PNG = 'PNG',
        POL = 'POL',
        PRI = 'PRI',
        PRK = 'PRK',
        PRT = 'PRT',
        PRY = 'PRY',
        PSE = 'PSE',
        PYF = 'PYF',
        QAT = 'QAT',
        REU = 'REU',
        ROU = 'ROU',
        RUS = 'RUS',
        RWA = 'RWA',
        SAU = 'SAU',
        SDN = 'SDN',
        SEN = 'SEN',
        SGP = 'SGP',
        SGS = 'SGS',
        SHN = 'SHN',
        SJM = 'SJM',
        SLB = 'SLB',
        SLE = 'SLE',
        SLV = 'SLV',
        SMR = 'SMR',
        SOM = 'SOM',
        SPM = 'SPM',
        SRB = 'SRB',
        SSD = 'SSD',
        STP = 'STP',
        SUR = 'SUR',
        SVK = 'SVK',
        SVN = 'SVN',
        SWE = 'SWE',
        SWZ = 'SWZ',
        SXM = 'SXM',
        SYC = 'SYC',
        SYR = 'SYR',
        TCA = 'TCA',
        TCD = 'TCD',
        TGO = 'TGO',
        THA = 'THA',
        TJK = 'TJK',
        TKL = 'TKL',
        TKM = 'TKM',
        TLS = 'TLS',
        TON = 'TON',
        TTO = 'TTO',
        TUN = 'TUN',
        TUR = 'TUR',
        TUV = 'TUV',
        TWN = 'TWN',
        TZA = 'TZA',
        UGA = 'UGA',
        UKR = 'UKR',
        UMI = 'UMI',
        URY = 'URY',
        USA = 'USA',
        UZB = 'UZB',
        VAT = 'VAT',
        VCT = 'VCT',
        VEN = 'VEN',
        VGB = 'VGB',
        VIR = 'VIR',
        VNM = 'VNM',
        VUT = 'VUT',
        WLF = 'WLF',
        WSM = 'WSM',
        YEM = 'YEM',
        ZAF = 'ZAF',
        ZMB = 'ZMB',
        ZWE = 'ZWE',
    }


}
