const earthColor = '#f9f5f0' //'#66655d'

// Highways
const highwayColor = ['step', ['zoom'], '#ad3f37', 15, 'white']
const highwayWidth = [
    'interpolate',
    ['exponential', 1.6],
    ['zoom'],
    3,
    0,
    6,
    0.7,
    12,
    2,
    18,
    32,
]
const highwayCasingColor = ['step', ['zoom'], '#ebcea2', 15, '#b3b3b3']
const highwayLabelColor = ['step', ['zoom'], 'white', 15, '#1c1a19']
const highwayLabelHaloColor = ['step', ['zoom'], '#802b25', 15, 'white']

// Major Roads
const majorRoadColor = ['step', ['zoom'], '#d16e66', 15, 'white']
const majorRoadLabelColor = ['step', ['zoom'], 'white', 15, '#1c1a19']
const majorRoadLabelHaloColor = ['step', ['zoom'], '#b85a53', 15, 'white']

// Roboto Bold until zoom 15, then switch to regular
const majorRoadFont = [
    'step',
    ['zoom'],
    ['literal', ['Noto Sans Bold']],
    15,
    ['literal', ['Noto Sans Regular']],
]

const majorRoadWidth = [
    'interpolate',
    ['exponential', 1.6],
    ['zoom'],
    7,
    0.6,
    7.5,
    0.7,
    12,
    0.9,
    19,
    32,
]

const majorRoadCasingZoom = 15
const majorRoadCasingColor = ['step', ['zoom'], '#b3b3b3', 15, '#b3b3b3'] //'#301e1c'
const majorRoadCasingWidth = [
    'interpolate',
    ['exponential', 1.6],
    ['zoom'],
    12,
    0,
    16,
    1,
]

// Medium Roads
const mediumRoadColor = ['step', ['zoom'], '#ffda45', 15, 'white']
const mediumRoadWidth = [
    'interpolate',
    ['exponential', 1.6],
    ['zoom'],
    7,
    0,
    7.5,
    0.5,
    19,
    31,
]
const mediumRoadCasingZoom = 13
const mediumRoadCasingColor = '#ab7c1c'
const mediumRoadCasingWidth = [
    'interpolate',
    ['exponential', 1.6],
    ['zoom'],
    8,
    0,
    11.5,
    0.01,
    12,
    0.2,
    20,
    1,
]

// Minor Roads
const minorRoadColor = ['step', ['zoom'], '#b3b3b3', 15, 'white']
const minorRoadWidth = [
    'interpolate',
    ['exponential', 1.6],
    ['zoom'],
    12,
    0,
    13,
    0.1,
    15.5,
    2,
    20,
    32,
]
const minorRoadCasingColor = '#b3b3b3'
const minorRoadCasingWidth = [
    'interpolate',
    ['exponential', 1.6],
    ['zoom'],
    8,
    0,
    11.5,
    0.01,
    12,
    0.2,
    20,
    1,
]
const minorRoadCasingZoom = 15

// Other Roads
const otherRoadColor = '#878787'
const roadsLabelColor = '#555454'
const physicalPointPeakColor = '#489a5d'
const naturalColor = '#e7edd3'

const layers = [
    {
        id: 'background',
        type: 'background',
        paint: {
            'background-color': '#f9f5f0',
        },
    },
    {
        id: 'earth',
        type: 'fill',
        source: 'protomaps',
        'source-layer': 'earth',
        paint: {
            'fill-color': earthColor,
        },
    },

    {
        id: 'landuse_residential',
        type: 'fill',
        source: 'protomaps',
        'source-layer': 'landuse',
        filter: ['any', ['==', 'pmap:kind', 'residential']],
        paint: {
            'fill-color': '#f5e9e9',
        },
    },
    {
        id: 'landuse_farmland',
        type: 'fill',
        source: 'protomaps',
        'source-layer': 'landuse',
        filter: ['any', ['==', 'pmap:kind', 'farmland']],
        paint: {
            'fill-color': '#EAE0C0',
            'fill-opacity': 0.2,
        },
    },
    {
        id: 'landuse_park',
        type: 'fill',
        source: 'protomaps',
        'source-layer': 'landuse',
        filter: ['any', ['==', 'pmap:kind', 'park']],
        paint: {
            'fill-color': '#d6dfbb',
        },
    },
    {
        id: 'landuse_garden_cemetary',
        type: 'fill',
        source: 'protomaps',
        'source-layer': 'landuse',
        filter: [
            'any',
            ['==', 'pmap:kind', 'garden'],
            ['==', 'landuse', 'cemetery'],
        ],
        paint: {
            'fill-color': '#d6dfbb',
        },
    },
    {
        id: 'landuse_grass',
        type: 'fill',
        source: 'protomaps',
        'source-layer': 'landuse',
        filter: ['any', ['==', 'pmap:kind', 'grass']],
        paint: {
            'fill-color': '#c9e6b8',
        },
    },
    {
        id: 'landuse_pitch',
        type: 'fill',
        source: 'protomaps',
        'source-layer': 'landuse',
        filter: ['any', ['==', 'pmap:kind', 'pitch']],
        paint: {
            'fill-color': '#c9e6b8',
        },
    },
    {
        id: 'landuse_pedestrian',
        type: 'fill',
        source: 'protomaps',
        'source-layer': 'landuse',
        filter: ['any', ['==', 'pmap:kind', 'pedestrian']],
        paint: {
            'fill-pattern': 'pedestrian_polygon',
        },
    },
    {
        id: 'landuse_hospital',
        type: 'fill',
        source: 'protomaps',
        'source-layer': 'landuse',
        filter: ['any', ['==', 'pmap:kind', 'hospital']],
        paint: {
            'fill-color': '#ffeae8',
        },
    },
    {
        id: 'landuse_school',
        type: 'fill',
        source: 'protomaps',
        'source-layer': 'landuse',
        filter: ['any', ['==', 'pmap:kind', 'school']],
        paint: {
            'fill-color': '#f2fef9',
        },
    },
    {
        id: 'natural_wood',
        type: 'fill',
        source: 'protomaps',
        'source-layer': 'natural',
        filter: [
            'any',
            ['==', 'natural', 'wood'],
            ['==', 'leisure', 'nature_reserve'],
            ['==', 'landuse', 'forest'],
        ],
        paint: {
            'fill-color': naturalColor,
        },
    },
    {
        id: 'natural_scrub',
        type: 'fill',
        source: 'protomaps',
        'source-layer': 'natural',
        filter: ['in', 'natural', 'scrub', 'grassland'],
        paint: {
            'fill-color': naturalColor,
        },
    },

    // AIRPORT CLASSES ---------------------------
    {
        id: 'landuse_aerodrome',
        type: 'fill',
        source: 'protomaps',
        'source-layer': 'landuse',
        filter: ['==', 'aeroway', 'aerodrome'],
        paint: {
            'fill-color': '#dbe7e7',
        },
    },
    {
        id: 'transit_aeroway_taxiway',
        type: 'line',
        source: 'protomaps',
        'source-layer': 'transit',
        filter: ['==', 'aeroway', 'taxiway'],
        paint: {
            'line-color': '#d1d9d9',
            'line-width': [
                'interpolate',
                ['exponential', 1.6],
                ['zoom'],
                9,
                0,
                9.5,
                0.4,
                14,
                7,
            ],
        },
    },
    {
        id: 'transit_aeroway_runway',
        type: 'line',
        source: 'protomaps',
        'source-layer': 'transit',
        filter: ['==', 'aeroway', 'runway'],
        paint: {
            'line-color': '#bfc9c9',

            'line-width': [
                'interpolate',
                ['exponential', 1.6],
                ['zoom'],
                9,
                0,
                9.5,
                0.7,
                14,
                12,
            ],
        },
    },
    {
        id: 'transit_aeroway_runway_label',
        type: 'symbol',
        source: 'protomaps',
        'source-layer': 'transit',
        filter: ['==', 'aeroway', 'runway'],
        minzoom: 14,
        layout: {
            'symbol-placement': 'line',
            'text-font': ['Noto Sans Regular'],
            'text-field': ['get', 'ref'],
            'text-size': 14,
            'text-letter-spacing': 0.3,
            // Only one smybol per line
            'text-allow-overlap': false,
            'text-ignore-placement': true,
        },
        paint: {
            'text-color': '#727d7d',
            'text-halo-color': 'white',
            'text-halo-width': 1.3,
        },
    },

    // -----------------------------------------

    {
        id: 'physical_line_river',
        type: 'line',
        source: 'protomaps',
        'source-layer': 'physical_line',
        minzoom: 9,
        filter: [
            'all',
            ['==', ['get', 'pmap:kind'], 'river'],
            ['!=', ['get', 'pmap:level'], -1],
        ],
        paint: {
            'line-color': '#a4cae1',
            'line-width': [
                'interpolate',
                ['exponential', 1.6],
                ['zoom'],
                9,
                0,
                9.5,
                1.0,
                18,
                12,
            ],
        },
    },
    {
        id: 'physical_line_river_tunnel',
        type: 'line',
        source: 'protomaps',
        'source-layer': 'physical_line',
        minzoom: 9,
        filter: [
            'all',
            ['==', ['get', 'pmap:kind'], 'river'],
            ['==', ['get', 'pmap:level'], -1],
        ],
        paint: {
            'line-color': '#a4cae1',
            'line-dasharray': [3, 3],

            'line-width': [
                'interpolate',
                ['exponential', 1.6],
                ['zoom'],
                9,
                0,
                9.5,
                1.0,
                18,
                12,
            ],
        },
    },
    {
        id: 'water',
        type: 'fill',
        source: 'protomaps',
        'source-layer': 'water',
        paint: {
            'fill-color': '#d8e6e3',
            'fill-outline-color': [
                'interpolate',
                ['linear'],
                ['zoom'],
                0,
                '#d8e6e3',
                7.5,
                '#d8e6e3',
                10,
                '#c0cfcb',
            ],
        },
    },
    {
        id: 'transit_waterways',
        type: 'line',
        source: 'protomaps',
        minzoom: 9,
        'source-layer': 'transit',
        filter: ['==', 'route', 'ferry'],
        paint: {
            'line-color': '#78a5bf',
            'line-width': 1,
            'line-dasharray': [3, 1],
        },
    },

    {
        id: 'transit_waterways_label',
        type: 'symbol',
        source: 'protomaps',
        'source-layer': 'transit',
        filter: ['==', 'route', 'ferry'],
        minzoom: 14,
        layout: {
            'symbol-placement': 'line',
            'text-font': ['Noto Sans Regular'],
            'text-field': ['get', 'name'],
            'text-size': 14,
            'text-letter-spacing': 0.3,
        },
        paint: {
            'text-color': '#305496',
            'text-halo-color': 'white',
            'text-halo-width': 1.3,
        },
    },
    {
        id: 'physical_line_waterway_tunnel',
        type: 'line',
        source: 'protomaps',
        'source-layer': 'physical_line',
        filter: [
            'all',
            ['==', ['get', 'pmap:kind'], 'waterway'],
            ['==', ['get', 'pmap:level'], -1],
        ],
        paint: {
            'line-color': '#94c1e1',
            'line-width': 0.8,
            'line-dasharray': [3, 3],
            'line-gap-width': {
                stops: [
                    [12, 0],
                    [20, 6],
                ],
            },
        },
    },

    // SATELLITE
    {
        id: 'sat',
        type: 'raster',
        source: 'eox-basemap',
        minzoom: 0,
        maxzoom: 15,
        paint: {
            'raster-opacity': [
                'interpolate',
                ['linear'],
                ['zoom'],
                0,
                1,
                10,
                0.9,
                15,
                0,
            ],
        },
        layout: {
            visibility: 'none',
        },
    },

    // ROAD SEGMENTS' CASING
    {
        id: 'roads_tunnels_other_casing',
        type: 'line',
        minzoom: 17,
        source: 'protomaps',
        'source-layer': 'roads',
        filter: ['all', ['<', 'pmap:level', 0], ['==', 'pmap:kind', 'other']],
        paint: {
            'line-color': '#ffffff',
            'line-gap-width': [
                'interpolate',
                ['exponential', 1.6],
                ['zoom'],
                14,
                0,
                14.5,
                0.5,
                20,
                12,
            ],
        },
        layout: {
            visibility: 'visible',
        },
    },
    {
        id: 'roads_tunnels_minor_casing',
        type: 'line',
        source: 'protomaps',
        'source-layer': 'roads',
        minzoom: minorRoadCasingZoom,
        filter: [
            'all',
            ['<', 'pmap:level', 0],
            ['==', 'pmap:kind', 'minor_road'],
        ],
        paint: {
            'line-color': minorRoadCasingColor,
            'line-dasharray': [3, 2],
            'line-gap-width': [
                'interpolate',
                ['exponential', 1.6],
                ['zoom'],
                12,
                0,
                12.5,
                0.5,
                20,
                32,
            ],
            'line-width': [
                'interpolate',
                ['exponential', 1.6],
                ['zoom'],
                12,
                0,
                12.5,
                1,
            ],
        },
        layout: {
            visibility: 'visible',
        },
    },
    {
        id: 'roads_tunnels_medium_casing',
        type: 'line',
        source: 'protomaps',
        'source-layer': 'roads',
        minzoom: mediumRoadCasingZoom,
        filter: [
            'all',
            ['<', 'pmap:level', 0],
            ['==', 'pmap:kind', 'medium_road'],
        ],
        paint: {
            'line-dasharray': [3, 2],
            'line-color': mediumRoadCasingColor,
            'line-gap-width': [
                'interpolate',
                ['exponential', 1.6],
                ['zoom'],
                7,
                0,
                7.5,
                0.5,
                20,
                32,
            ],
            'line-width': mediumRoadCasingWidth,
        },
        layout: {
            visibility: 'visible',
        },
    },
    {
        id: 'roads_tunnels_major_casing',
        type: 'line',
        source: 'protomaps',
        'source-layer': 'roads',
        minzoom: majorRoadCasingZoom,
        filter: [
            'all',
            ['<', 'pmap:level', 0],
            ['==', 'pmap:kind', 'major_road'],
        ],
        paint: {
            'line-color': majorRoadCasingColor,
            'line-dasharray': [3, 2],
            'line-gap-width': [
                'interpolate',
                ['exponential', 1.6],
                ['zoom'],
                7,
                0,
                7.5,
                0.5,
                19,
                32,
            ],
            'line-width': majorRoadCasingWidth,
        },
        layout: {
            visibility: 'visible',
        },
    },
    {
        id: 'roads_tunnels_highway_casing',
        type: 'line',
        source: 'protomaps',
        'source-layer': 'roads',
        filter: ['all', ['<', 'pmap:level', 0], ['==', 'pmap:kind', 'highway']],
        paint: {
            'line-color': highwayCasingColor,
            'line-dasharray': [3, 2],
            'line-gap-width': [
                'interpolate',
                ['exponential', 1.6],
                ['zoom'],
                3,
                0,
                3.5,
                0.5,
                18,
                32,
            ],
            'line-width': [
                'interpolate',
                ['exponential', 1.6],
                ['zoom'],
                7,
                0,
                7.5,
                1,
            ],
        },
        layout: {
            visibility: 'visible',
        },
    },
    {
        id: 'roads_minor_casing',
        type: 'line',
        source: 'protomaps',
        'source-layer': 'roads',
        minzoom: minorRoadCasingZoom,
        filter: [
            'all',
            ['==', 'pmap:level', 0],
            ['==', 'pmap:kind', 'minor_road'],
        ],
        paint: {
            'line-color': minorRoadCasingColor,
            'line-gap-width': [
                'interpolate',
                ['exponential', 1.6],
                ['zoom'],
                12,
                0,
                12.5,
                0.5,
                20,
                32,
            ],
            'line-width': minorRoadCasingWidth,
        },
        layout: {
            visibility: 'visible',
        },
    },
    {
        id: 'roads_medium_casing',
        type: 'line',
        source: 'protomaps',
        'source-layer': 'roads',
        minzoom: mediumRoadCasingZoom,

        filter: [
            'all',
            ['==', 'pmap:level', 0],
            ['==', 'pmap:kind', 'medium_road'],
        ],
        paint: {
            'line-color': mediumRoadCasingColor,
            'line-gap-width': [
                'interpolate',
                ['exponential', 1.6],
                ['zoom'],
                7,
                0,
                7.5,
                0.5,
                20,
                32,
            ],
            'line-width': mediumRoadCasingWidth,
        },
        layout: {
            visibility: 'visible',
        },
    },
    {
        id: 'roads_major_casing',
        type: 'line',
        source: 'protomaps',
        'source-layer': 'roads',
        minzoom: majorRoadCasingZoom, //10.5
        filter: [
            'all',
            ['==', 'pmap:level', 0],
            ['==', 'pmap:kind', 'major_road'],
        ],
        paint: {
            'line-color': majorRoadCasingColor, //'#e3cfd3',
            'line-gap-width': [
                'interpolate',
                ['exponential', 1.6],
                ['zoom'],
                7,
                0,
                7.5,
                2,
                19,
                32,
            ],
            'line-width': majorRoadCasingWidth,
        },
        layout: {
            visibility: 'visible',
        },
    },
    {
        id: 'roads_highway_casing',
        type: 'line',
        source: 'protomaps',
        'source-layer': 'roads',
        filter: [
            'all',
            ['==', 'pmap:level', 0],
            ['==', 'pmap:kind', 'highway'],
        ],
        paint: {
            'line-color': highwayCasingColor,
            'line-gap-width': [
                'interpolate',
                ['exponential', 1.6],
                ['zoom'],
                3,
                0,
                3.5,
                0.5,
                18,
                32,
            ],
            'line-width': [
                'interpolate',
                ['exponential', 1.6],
                ['zoom'],
                7,
                0,
                7.5,
                1,
            ],
        },
        layout: {
            visibility: 'visible',
        },
    },
    {
        id: 'roads_bridges_other_casing',
        type: 'line',
        minzoom: 17,
        source: 'protomaps',
        'source-layer': 'roads',
        filter: ['all', ['>', 'pmap:level', 0], ['==', 'pmap:kind', 'other']],
        paint: {
            'line-color': '#ffffff',
            'line-gap-width': [
                'interpolate',
                ['exponential', 1.6],
                ['zoom'],
                14,
                0,
                14.5,
                0.5,
                20,
                12,
            ],
        },
        layout: {
            visibility: 'visible',
        },
    },
    {
        id: 'roads_bridges_minor_casing',
        type: 'line',
        minzoom: minorRoadCasingZoom,
        source: 'protomaps',
        'source-layer': 'roads',
        filter: [
            'all',
            ['>', 'pmap:level', 0],
            ['==', 'pmap:kind', 'minor_road'],
        ],
        paint: {
            'line-color': minorRoadColor,
            'line-gap-width': [
                'interpolate',
                ['exponential', 1.6],
                ['zoom'],
                12,
                0,
                12.5,
                0.5,
                20,
                32,
            ],
            'line-width': [
                'interpolate',
                ['exponential', 1.6],
                ['zoom'],
                12,
                0,
                12.5,
                1,
            ],
        },
        layout: {
            visibility: 'visible',
        },
    },
    {
        id: 'roads_bridges_medium_casing',
        type: 'line',
        source: 'protomaps',
        'source-layer': 'roads',
        filter: [
            'all',
            ['>', 'pmap:level', 0],
            ['==', 'pmap:kind', 'medium_road'],
        ],
        paint: {
            'line-color': '#e1e1e1',
            'line-gap-width': [
                'interpolate',
                ['exponential', 1.6],
                ['zoom'],
                7,
                0,
                7.5,
                0.5,
                20,
                32,
            ],
            'line-width': [
                'interpolate',
                ['exponential', 1.6],
                ['zoom'],
                10,
                0,
                10.5,
                1,
            ],
        },
        layout: {
            visibility: 'visible',
        },
    },
    {
        id: 'roads_bridges_major_casing',
        type: 'line',
        source: 'protomaps',
        'source-layer': 'roads',
        minzoom: majorRoadCasingZoom,
        filter: [
            'all',
            ['>', 'pmap:level', 0],
            ['==', 'pmap:kind', 'major_road'],
        ],
        paint: {
            'line-color': '#e3cfd3',
            'line-gap-width': [
                'interpolate',
                ['exponential', 1.6],
                ['zoom'],
                7,
                0,
                7.5,
                0.5,
                19,
                32,
            ],
            'line-width': [
                'interpolate',
                ['exponential', 1.6],
                ['zoom'],
                9,
                0,
                9.5,
                1,
            ],
        },
        layout: {
            visibility: 'visible',
        },
    },
    {
        id: 'roads_bridges_highway_casing',
        type: 'line',
        source: 'protomaps',
        'source-layer': 'roads',
        filter: ['all', ['>', 'pmap:level', 0], ['==', 'pmap:kind', 'highway']],
        paint: {
            'line-color': highwayCasingColor,
            'line-gap-width': [
                'interpolate',
                ['exponential', 1.6],
                ['zoom'],
                3,
                0,
                3.5,
                0.5,
                18,
                32,
            ],
            'line-width': [
                'interpolate',
                ['exponential', 1.6],
                ['zoom'],
                7,
                0,
                7.5,
                1,
            ],
        },
        layout: {
            visibility: 'visible',
        },
    },

    // ROAD SEGMENTS
    {
        id: 'roads_paths',
        type: 'line',
        source: 'protomaps',
        'source-layer': 'roads',
        filter: ['all', ['==', 'pmap:kind', 'path']],
        minzoom: 14,
        paint: {
            'line-color': '#754a08',
            'line-dasharray': [3, 6],
            'line-width': [
                'interpolate',
                ['exponential', 1.6],
                ['zoom'],
                14,
                0,

                20,
                2,
            ],
        },
    },
    {
        id: 'roads_tunnels_other',
        type: 'line',
        source: 'protomaps',
        'source-layer': 'roads',
        filter: ['all', ['<', 'pmap:level', 0], ['==', 'pmap:kind', 'other']],
        paint: {
            'line-color': '#f7f7f7',
            'line-dasharray': [1, 1],
            'line-width': [
                'interpolate',
                ['exponential', 1.6],
                ['zoom'],
                14,
                0,
                14.5,
                0.5,
                20,
                12,
            ],
        },
    },

    {
        id: 'roads_tunnels_minor',
        type: 'line',
        source: 'protomaps',
        'source-layer': 'roads',
        filter: [
            'all',
            ['<', 'pmap:level', 0],
            ['==', 'pmap:kind', 'minor_road'],
        ],
        paint: {
            'line-color': minorRoadColor,
            'line-width': minorRoadWidth,
        },
    },

    {
        id: 'roads_tunnels_medium',
        type: 'line',
        source: 'protomaps',
        'source-layer': 'roads',
        filter: [
            'all',
            ['<', 'pmap:level', 0],
            ['==', 'pmap:kind', 'medium_road'],
        ],
        paint: {
            'line-color': mediumRoadColor,
            'line-width': mediumRoadWidth,
        },
    },

    {
        id: 'roads_tunnels_major',
        type: 'line',
        source: 'protomaps',
        'source-layer': 'roads',
        filter: [
            'all',
            ['<', 'pmap:level', 0],
            ['==', 'pmap:kind', 'major_road'],
        ],
        paint: {
            'line-dasharray': [3, 2],
            'line-color': majorRoadColor,
            'line-width': majorRoadWidth,
        },
    },

    {
        id: 'roads_tunnels_highway',
        type: 'line',
        source: 'protomaps',
        'source-layer': 'roads',
        filter: ['all', ['<', 'pmap:level', 0], ['==', 'pmap:kind', 'highway']],
        paint: {
            'line-color': highwayColor,
            'line-dasharray': [3, 2],
            'line-width': [
                'interpolate',
                ['exponential', 1.6],
                ['zoom'],
                3,
                0,
                3.5,
                0.5,
                18,
                32,
            ],
        },
    },
    {
        id: 'physical_line_waterway',
        type: 'line',
        source: 'protomaps',
        'source-layer': 'physical_line',
        filter: [
            'all',
            ['==', ['get', 'pmap:kind'], 'waterway'],
            ['==', ['get', 'pmap:level'], 0],
        ],
        paint: {
            'line-color': '#94c1e1',
            'line-width': 0.8,
        },
    },

    // Road segments
    {
        id: 'roads_other',
        type: 'line',
        source: 'protomaps',
        'source-layer': 'roads',
        filter: ['all', ['==', 'pmap:level', 0], ['==', 'pmap:kind', 'other']],
        paint: {
            'line-color': otherRoadColor,
            'line-dasharray': [2, 1],
            'line-width': [
                'interpolate',
                ['exponential', 1.6],
                ['zoom'],
                14,
                0,
                14.5,
                0.2,
                20,
                4,
            ],
        },
    },

    {
        id: 'roads_minor',
        type: 'line',
        source: 'protomaps',
        'source-layer': 'roads',
        filter: [
            'all',
            ['==', 'pmap:level', 0],
            ['==', 'pmap:kind', 'minor_road'],
        ],
        paint: {
            'line-color': minorRoadColor,
            'line-width': minorRoadWidth,
        },
    },

    {
        id: 'roads_medium',
        type: 'line',
        source: 'protomaps',
        'source-layer': 'roads',
        filter: [
            'all',
            ['==', 'pmap:level', 0],
            ['==', 'pmap:kind', 'medium_road'],
        ],
        paint: {
            'line-color': mediumRoadColor,
            'line-width': [
                'interpolate',
                ['exponential', 1.6],
                ['zoom'],
                7,
                0,
                7.5,
                0.5,
                20,
                32,
            ],
        },
    },

    {
        id: 'roads_major',
        type: 'line',
        source: 'protomaps',
        'source-layer': 'roads',
        filter: [
            'all',
            ['==', 'pmap:level', 0],
            ['==', 'pmap:kind', 'major_road'],
        ],
        paint: {
            'line-color': majorRoadColor,
            'line-width': majorRoadWidth,
        },
    },

    {
        id: 'roads_highway',
        type: 'line',
        source: 'protomaps',
        'source-layer': 'roads',
        filter: [
            'all',
            ['==', 'pmap:level', 0],
            ['==', 'pmap:kind', 'highway'],
        ],
        paint: {
            'line-color': highwayColor,
            'line-width': highwayWidth,
        },
    },
    {
        id: 'transit_railway',
        type: 'line',
        source: 'protomaps',
        'source-layer': 'transit',
        filter: ['all', ['==', 'pmap:kind', 'railway']],
        paint: {
            'line-color': '#b3bcc9',
            'line-width': 2,
        },
    },
    {
        id: 'boundaries_country',
        type: 'line',
        source: 'protomaps',
        'source-layer': 'boundaries',
        filter: ['<=', 'pmap:min_admin_level', 2],
        paint: {
            'line-color': '#5c5c5c',
            'line-width': 1,
            'line-dasharray': [3, 2],
        },
    },
    {
        id: 'boundaries',
        type: 'line',
        source: 'protomaps',
        'source-layer': 'boundaries',
        filter: ['>', 'pmap:min_admin_level', 2],
        paint: {
            'line-color': '#5c5c5c',
            'line-width': 0.5,
            'line-dasharray': [3, 2],
        },
    },
    {
        id: 'transit_railway_tracks',
        type: 'line',
        source: 'protomaps',
        'source-layer': 'transit',
        filter: ['all', ['==', 'pmap:kind', 'railway']],
        paint: {
            'line-color': '#ffffff',
            'line-width': 0.8,
            'line-dasharray': [6, 10],
        },
    },
    {
        id: 'transit_ferry',
        type: 'line',
        source: 'protomaps',
        'source-layer': 'transit',
        filter: ['all', ['==', 'pmap:kind', 'ferry']],
        paint: {
            'line-color': otherRoadColor,
            'line-dasharray': [1, 5],
            'line-width': 0.3,
        },
    },
    //{
    //    id: 'boundaries',
    //    type: 'line',
    //    source: 'protomaps',
    //    'source-layer': 'boundaries',
    //    paint: {
    //        'line-color': '#5c4a6b',
    //        'line-width': 0.5,
    //        'line-dasharray': [3, 2],
    //    },
    //},

    {
        id: 'roads_bridges_other',
        type: 'line',
        source: 'protomaps',
        'source-layer': 'roads',
        filter: ['all', ['>', 'pmap:level', 0], ['==', 'pmap:kind', 'other']],
        paint: {
            'line-color': '#ffffff',
            'line-dasharray': [2, 1],
            'line-width': [
                'interpolate',
                ['exponential', 1.6],
                ['zoom'],
                14,
                0,
                14.5,
                0.5,
                20,
                12,
            ],
        },
    },

    {
        id: 'roads_bridges_minor',
        type: 'line',
        source: 'protomaps',
        'source-layer': 'roads',
        filter: [
            'all',
            ['>', 'pmap:level', 0],
            ['==', 'pmap:kind', 'minor_road'],
        ],
        paint: {
            'line-color': minorRoadColor,
            'line-width': [
                'interpolate',
                ['exponential', 1.6],
                ['zoom'],
                12,
                0,
                12.5,
                0.5,
                20,
                32,
            ],
        },
    },

    {
        id: 'roads_bridges_medium',
        type: 'line',
        source: 'protomaps',
        'source-layer': 'roads',
        filter: [
            'all',
            ['>', 'pmap:level', 0],
            ['==', 'pmap:kind', 'medium_road'],
        ],
        paint: {
            'line-color': mediumRoadColor,
            'line-width': [
                'interpolate',
                ['exponential', 1.6],
                ['zoom'],
                7,
                0,
                7.5,
                0.5,
                20,
                32,
            ],
        },
    },

    {
        id: 'roads_bridges_major',
        type: 'line',
        source: 'protomaps',
        'source-layer': 'roads',
        filter: [
            'all',
            ['>', 'pmap:level', 0],
            ['==', 'pmap:kind', 'major_road'],
        ],
        paint: {
            'line-color': majorRoadColor,
            'line-width': majorRoadWidth,
        },
    },

    {
        id: 'roads_bridges_highway',
        type: 'line',
        source: 'protomaps',
        'source-layer': 'roads',
        filter: ['all', ['>', 'pmap:level', 0], ['==', 'pmap:kind', 'highway']],
        paint: {
            'line-color': highwayColor,
            'line-width': [
                'interpolate',
                ['exponential', 1.6],
                ['zoom'],
                3,
                0,
                3.5,
                0.5,
                18,
                32,
            ],
        },
    },
    {
        id: 'physical_line_waterway_label',
        type: 'symbol',
        source: 'protomaps',
        'source-layer': 'physical_line',
        minzoom: 14,
        layout: {
            'symbol-placement': 'line',
            'text-font': ['Noto Sans Regular'],
            'text-field': ['get', 'name'],
            'text-size': 14,
            'text-letter-spacing': 0.3,
        },
        paint: {
            'text-color': '#305496',
            'text-halo-color': 'white',
            'text-halo-width': 1.3,
        },
    },

    {
        id: 'road_one_way_arrow',
        type: 'symbol',
        source: 'protomaps',
        'source-layer': 'roads',
        minzoom: 16,
        filter: ['==', 'oneway', 'yes'],
        layout: {
            'icon-image': 'arrow',
            'symbol-placement': 'line',
        },
    },

    // road labesl
    {
        id: 'roads_labels',
        type: 'symbol',
        source: 'protomaps',
        'source-layer': 'roads',
        filter: [
            'all',
            ['!=', 'pmap:kind', 'highway'],
            ['!=', 'pmap:kind', 'major_road'],
        ],
        layout: {
            'symbol-placement': 'line',
            'text-font': ['Noto Sans Regular'],
            'text-field': ['get', 'name'],
            'text-size': {
                stops: [
                    [13, 9],
                    [16, 12],
                ],
            },
        },
        paint: {
            'text-color': roadsLabelColor,
            'text-halo-color': 'white',
            'text-halo-width': 1,
        },
    },
    {
        id: 'roads_highway_label',
        type: 'symbol',
        source: 'protomaps',
        'source-layer': 'roads',
        filter: ['==', 'pmap:kind', 'highway'],
        layout: {
            'symbol-placement': 'line',
            'text-font': ['Noto Sans Bold'],
            'text-field': ['get', 'name'],
            'text-size': 12,
        },
        paint: {
            'text-color': highwayLabelColor,
            'text-halo-color': highwayLabelHaloColor, // highwayLabelColor,
            'text-halo-width': 1,
        },
    },
    {
        id: 'roads_major_label',
        type: 'symbol',
        source: 'protomaps',
        'source-layer': 'roads',
        filter: ['==', 'pmap:kind', 'major_road'],
        layout: {
            'symbol-placement': 'line',
            'text-font': majorRoadFont,
            'text-field': ['get', 'name'],
            'text-size': 12,
        },
        paint: {
            'text-color': majorRoadLabelColor,
            'text-halo-color': majorRoadLabelHaloColor, //'white',
            'text-halo-width': 1.6,
        },
    },

    {
        id: 'buildings-extrusion',
        type: 'fill-extrusion',
        source: 'protomaps',
        'source-layer': 'buildings',
        filter: ['==', 'pmap:kind', 'building'],
        minzoom: 15,
        paint: {
            'fill-extrusion-color': '#d9d9d8', // "#144b6f",
            'fill-extrusion-height': [
                'case',
                ['>', ['to-number', ['get', 'height']], 0],
                ['to-number', ['get', 'height']],
                10,
            ],
            'fill-extrusion-opacity': 0.3,
        },
    },
    {
        id: 'building-part-3d',
        type: 'fill-extrusion',
        source: 'protomaps',
        'source-layer': 'buildings',
        filter: ['==', 'pmap:kind', 'building_part'],
        minzoom: 15,
        paint: {
            'fill-extrusion-color': '#d9d9d8',
            'fill-extrusion-opacity': 0.3,
            'fill-extrusion-height': {
                property: 'height',
                type: 'identity',
            },
            'fill-extrusion-base': {
                property: 'min_height',
                type: 'identity',
            },
            'fill-extrusion-opacity': 0.8,
        },
    },
    {
        id: 'physical_point_ocean',
        type: 'symbol',
        source: 'protomaps',
        'source-layer': 'physical_point',
        filter: ['any', ['==', 'place', 'sea'], ['==', 'place', 'ocean']],
        layout: {
            'text-field': '{name:en}\n{name:el}',
            'text-font': ['Noto Sans Regular'],
            'text-max-width': 5,
            'text-size': 11,
        },
        paint: {
            'text-color': '#728f8d',
            'text-halo-color': 'rgba(255,255,255,0.7)',
            'text-halo-width': 1,
        },
    },
    {
        id: 'physical_point_peak',
        type: 'symbol',
        source: 'protomaps',
        'source-layer': 'physical_point',
        filter: ['any', ['==', 'natural', 'peak']],
        layout: {
            'text-font': ['Noto Sans Bold'],
            'text-field': ['get', 'name'],
            'text-size': 11,
        },
        paint: {
            'text-color': physicalPointPeakColor,
            'text-halo-color': '#ffffff',
            'text-halo-width': 1.5,
        },
    },
    {
        id: 'physical_point_strait_bay_lake_water',
        type: 'symbol',
        minzoom: 8,
        source: 'protomaps',
        'source-layer': 'physical_point',
        filter: [
            'any',
            ['==', 'pmap:kind', 'strait'],
            ['==', 'pmap:kind', 'bay'],
            ['==', 'pmap:kind', 'lake'],
            ['==', 'pmap:kind', 'water'],
        ],
        layout: {
            'text-font': ['Noto Sans Regular'],
            'text-field': '{name:en}\n{name:el}',
            'text-size': 11,
        },
        paint: {
            'text-color': '#417b9c',
            'text-halo-color': '#fff',
            'text-halo-width': 1,
        },
    },
    {
        id: 'poi-marker',
        type: 'circle',
        source: 'protomaps',
        'source-layer': 'pois',
        filter: ['has', 'name'], // ["!=", "name", 'none'],
        minzoom: 17.7,
        paint: {
            'circle-radius': {
                base: 1,
                stops: [
                    [16, 0.8],
                    [18, 3],
                ],
            },
            'circle-color': '#000', // "#FF6E40",
            'circle-opacity': 0.6,
        },
    },
    {
        id: 'poi-icon',
        type: 'symbol',
        source: 'protomaps',
        'source-layer': 'pois',
        filter: [
            'all',
            ['has', 'name'],
            ['!=', 'name', ''],
            ['!=', 'pmap:kind', 'cafe'],
            ['!=', 'pmap:kind', 'bakery'],
            ['!=', 'pmap:kind', 'restaurant'],
            ['!=', 'pmap:kind', 'fast_food'],
            ['!=', 'pmap:kind', 'ice_cream'],

            ['!=', 'pmap:kind', 'bar'],
            ['!=', 'pmap:kind', 'alcohol'],

            ['!=', 'pmap:kind', 'kiosk'],

            ['!=', 'pmap:kind', 'gift'],
            ['!=', 'pmap:kind', 'jewelry'],
            ['!=', 'pmap:kind', 'leather'],
            ['!=', 'pmap:kind', 'bag'],
        ],
        minzoom: 15,
        layout: {
            'icon-image': ['get', 'pmap:kind'],
            'icon-size': ['interpolate', ['linear'], ['zoom'], 14, 0.7, 18, 1],
            'icon-allow-overlap': true,
            'icon-ignore-placement': true,
            'icon-padding': 1,
            'icon-optional': true,
        },
    },

    {
        id: 'poi-label',
        type: 'symbol',
        source: 'protomaps',
        'source-layer': 'pois',
        filter: ['!=', 'name', ''],
        minzoom: 18,
        layout: {
            'text-field': '{name}',
            'text-font': ['Noto Sans Regular'],
            'text-anchor': 'top',
            'text-offset': {
                stops: [
                    [16, [0, 0]],
                    [17, [0, 1]],
                    [18, [0, 1.1]],
                ],
            },
            'text-size': {
                stops: [
                    [14, 8],
                    [18, 10],
                ],
            },
        },
        paint: {
            'text-color': {
                stops: [
                    [16, '#9c807b'],
                    [17, '#695956'],
                    [18, '#4a4140'],
                ],
            },
            'text-halo-color': '#fff',
            'text-halo-width': 0.7,
        },
    },

    {
        id: 'road_shield',
        type: 'symbol',
        source: 'protomaps',
        'source-layer': 'roads',
        minzoom: 7,
        filter: [
            'all',
            ['<=', 'shield_text_length', 6],
            ['!=', 'pmap:kind', 'path'],
        ],
        layout: {
            'icon-image': 'default_{shield_text_length}',
            'icon-rotation-alignment': 'viewport',
            'symbol-placement': {
                base: 1,
                stops: [
                    [10, 'point'],
                    [11, 'line'],
                ],
            },
            'symbol-spacing': 500,
            'text-field': '{ref}',
            'text-font': ['Noto Sans Regular'],
            'text-offset': [0, 0.1],
            'text-rotation-alignment': 'viewport',
            'text-size': 10,
            'icon-size': 0.8,
        },
        paint: {
            'text-color': '#fff',
            'text-halo-color': '#ccffe8',
            'text-halo-width': 0.2,
        },
    },
    {
        id: 'places_subplace',
        type: 'symbol',
        source: 'protomaps',
        'source-layer': 'places',
        filter: ['==', 'pmap:kind', 'neighbourhood'],
        layout: {
            'text-field': '{name}',
            'text-font': ['Noto Sans Bold'],
            'text-size': {
                base: 1.2,
                stops: [
                    [11, 10],
                    [14, 12],
                ],
            },
            'text-transform': 'none',
        },
        paint: {
            'text-color': '#787878',
            'text-halo-color': '#fff',
            'text-halo-width': 1,
        },
    },
    {
        id: 'places_city_circle',
        type: 'circle',
        source: 'protomaps',
        'source-layer': 'places',
        filter: ['==', 'pmap:kind', 'locality'],
        paint: {
            'circle-radius': 2,
            'circle-stroke-width': 2,
            'circle-stroke-color': 'white',
            'circle-color': '#666666',
        },
        maxzoom: 8,
    },
    {
        id: 'places_locality',
        type: 'symbol',
        source: 'protomaps',
        'source-layer': 'places',
        filter: ['==', 'pmap:kind', 'locality'],
        layout: {
            'text-field': '{name:el}',
            'text-font': [
                'case',
                ['<=', ['get', 'pmap:min_zoom'], 7],
                ['literal', ['Noto Sans Bold']],
                ['literal', ['Noto Sans Regular']],
            ],
            'text-padding': [
                'interpolate',
                ['linear'],
                ['zoom'],
                5,
                3,
                8,
                7,
                12,
                11,
            ],
            'text-size': [
                'interpolate',
                ['linear'],
                ['zoom'],
                2,
                [
                    'case',
                    ['<', ['get', 'pmap:population_rank'], 13],
                    8,
                    ['>=', ['get', 'pmap:population_rank'], 13],
                    13,
                    0,
                ],
                4,
                [
                    'case',
                    ['<', ['get', 'pmap:population_rank'], 13],
                    10,
                    ['>=', ['get', 'pmap:population_rank'], 13],
                    15,
                    0,
                ],
                6,
                [
                    'case',
                    ['<', ['get', 'pmap:population_rank'], 12],
                    11,
                    ['>=', ['get', 'pmap:population_rank'], 12],
                    17,
                    0,
                ],
                8,
                [
                    'case',
                    ['<', ['get', 'pmap:population_rank'], 11],
                    11,
                    ['>=', ['get', 'pmap:population_rank'], 11],
                    18,
                    0,
                ],
                10,
                [
                    'case',
                    ['<', ['get', 'pmap:population_rank'], 9],
                    12,
                    ['>=', ['get', 'pmap:population_rank'], 9],
                    20,
                    0,
                ],
                15,
                [
                    'case',
                    ['<', ['get', 'pmap:population_rank'], 8],
                    12,
                    ['>=', ['get', 'pmap:population_rank'], 8],
                    22,
                    0,
                ],
            ],
            'icon-padding': [
                'interpolate',
                ['linear'],
                ['zoom'],
                0,
                2,
                8,
                4,
                10,
                8,
                12,
                6,
                22,
                2,
            ],
            'text-anchor': ['step', ['zoom'], 'left', 8, 'center'],
            'text-radial-offset': 0.2,
        },
        paint: {
            'text-color': '#000',
            'text-halo-color': 'white',
            'text-halo-width': 1,
        },
    },
    //{
    //    id: 'places_city',
    //    type: 'symbol',
    //    source: 'protomaps',
    //    'source-layer': 'places',
    //    filter: ['==', 'pmap:kind', 'locality'],
    //    layout: {
    //        'text-field': '{name}',
    //        'text-font': ['Noto Sans Bold'],
    //        'text-size': ['step', ['get', 'pmap:rank'], 0, 1, 14, 2, 10],
    //        'text-variable-anchor': ['bottom-left'],
    //        'text-radial-offset': 0.2,
    //    },
    //    paint: {
    //        'text-color': '#000',
    //        'text-halo-color': 'white',
    //        'text-halo-width': 1,
    //    },
    //},
]

export { layers }
