import React, { useEffect } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import { AuthProvider } from '@/modules/auth'
import { DataProvider } from '@/modules/core/context/DataContext'
import { MapProvider } from '@/modules/map'
import { RoutingProvider } from '@/packages/routing/context'
import { GoogleOAuthProvider } from '@react-oauth/google'

// analytics
import { AnalyticsProvider } from '@/packages/analytics/analytics/analytics-context'
import AnalyticsHandler from '@/packages/analytics/analytics/analytics-handler'

// cookies
import AppCookieConsent from '@/packages/analytics/cookies/CookieConsent'

import Suspended from '@/modules/core/components/Suspended'

import ProtectedRoute from '@/routes/_ProtectedRoute'
import StaffProtectedRoute from '@/routes/_StaffProtectedRoute'
import NotFound from '@/routes/error/NotFound'
import PageLayout from '@/modules/core/layout/PageLayout'

import HomePage from '@/modules/core/routes/Home'
const AboutPage = React.lazy(() => import('@/modules/core/routes/About'))
const DisclaimerPage = React.lazy(() =>
    import('@/modules/core/routes/DisclaimerPage')
)
const PriceDashboard = React.lazy(() =>
    import('@/modules/core/routes/PriceDashboard')
)
const InitialSearchWizard = React.lazy(() =>
    import('@/modules/core/routes/InitialSearchWizard')
)

const Login = React.lazy(() => import('@/modules/accounts/routes/Login'))
const Signup = React.lazy(() => import('@/modules/accounts/routes/Signup'))
const ForgotPassword = React.lazy(() =>
    import('@/modules/accounts/routes/ForgotPassword')
)
const PasswordResetConfirm = React.lazy(() =>
    import('@/modules/accounts/routes/PasswordResetConfirm')
)
const ResendVerificationEmail = React.lazy(() =>
    import('@/modules/accounts/routes/ResendVerificationEmail')
)

const UserProfile = React.lazy(() =>
    import('@/modules/accounts/routes/UserProfile')
)
const ChangePassword = React.lazy(() =>
    import('@/modules/accounts/routes/ChangePassword')
)
const AddChargingPolicy = React.lazy(() =>
    import('@/modules/operators/routes/AddChargingPolicy')
)
const ListChargingPolicy = React.lazy(() =>
    import('@/modules/operators/routes/ListChargingPolicy')
)
const AddPolicyGroup = React.lazy(() =>
    import('@/modules/operators/routes/AddPolicyGroup')
)
const ListPolicyGroup = React.lazy(() =>
    import('@/modules/operators/routes/ListPolicyGroup')
)
const StatisticsPage = React.lazy(() =>
    import('@/modules/core/routes/admin/Statistics')
)
const CompanyStatsDetails = React.lazy(() =>
    import('@/modules/core/routes/admin/CompanyStatsDetails')
)

import maplibregl from 'maplibre-gl'
import { Protocol } from 'pmtiles'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const queryClient = new QueryClient()

function App() {
    useEffect(() => {
        const protocol = new Protocol()
        maplibregl.addProtocol('pmtiles', protocol.tile)
        return () => {
            maplibregl.removeProtocol('pmtiles')
        }
    }, [])

    return (
        <>
            <AnalyticsProvider>
                <QueryClientProvider client={queryClient}>
                    <GoogleOAuthProvider
                        clientId={import.meta.env.VITE_GOOGLE_CLIENT_ID}
                    >
                        <AuthProvider>
                            <BrowserRouter
                                basename={
                                    import.meta.env.VITE_FRONTEND_ROOT || '/app'
                                }
                            >
                                <AnalyticsHandler>
                                    <DataProvider>
                                        <Routes>
                                            <Route
                                                path="/"
                                                element={<PageLayout />}
                                            >
                                                <Route
                                                    index
                                                    element={<HomePage />}
                                                />
                                                <Route
                                                    path="/about"
                                                    element={
                                                        <Suspended>
                                                            <AboutPage />
                                                        </Suspended>
                                                    }
                                                />
                                                <Route
                                                    path="/disclaimer"
                                                    element={
                                                        <Suspended>
                                                            <DisclaimerPage />
                                                        </Suspended>
                                                    }
                                                />
                                                {/* <Route
                                                    path="/login"
                                                    element={<Login />}
                                                /> */}
                                                <Route
                                                    path="/login/:extra_params?"
                                                    element={
                                                        <Suspended>
                                                            <Login />
                                                        </Suspended>
                                                    }
                                                />
                                                <Route
                                                    path="/signup"
                                                    element={
                                                        <Suspended>
                                                            <Signup />
                                                        </Suspended>
                                                    }
                                                />
                                                <Route
                                                    path="/reset-password"
                                                    element={
                                                        <Suspended>
                                                            <ForgotPassword />
                                                        </Suspended>
                                                    }
                                                />
                                                <Route
                                                    path="/password-reset-confirm/:uid/:token"
                                                    element={
                                                        <Suspended>
                                                            <PasswordResetConfirm />
                                                        </Suspended>
                                                    }
                                                />
                                                <Route
                                                    path="/resend-verification-email"
                                                    element={
                                                        <Suspended>
                                                            <ResendVerificationEmail />
                                                        </Suspended>
                                                    }
                                                />
                                                <Route
                                                    path="initial-search"
                                                    element={
                                                        <Suspended>
                                                            <InitialSearchWizard />
                                                        </Suspended>
                                                    }
                                                />
                                                <Route
                                                    path="/dashboard"
                                                    element={
                                                        <MapProvider>
                                                            <RoutingProvider>
                                                                <Suspended>
                                                                    <PriceDashboard />
                                                                </Suspended>
                                                            </RoutingProvider>
                                                        </MapProvider>
                                                    }
                                                    errorElement={<NotFound />}
                                                />
                                                <Route
                                                    path="/user-profile"
                                                    element={
                                                        <Suspended>
                                                            <ProtectedRoute>
                                                                <UserProfile />
                                                            </ProtectedRoute>
                                                        </Suspended>
                                                    }
                                                />
                                                <Route
                                                    path="/change-password"
                                                    element={
                                                        <Suspended>
                                                            <ProtectedRoute>
                                                                <ChangePassword />
                                                            </ProtectedRoute>
                                                        </Suspended>
                                                    }
                                                />
                                                <Route
                                                    path="/add-policy"
                                                    element={
                                                        <Suspended>
                                                            <StaffProtectedRoute
                                                                roles={[
                                                                    'is_operator_staff',
                                                                    'is_superuser',
                                                                ]}
                                                            >
                                                                <AddChargingPolicy />
                                                            </StaffProtectedRoute>
                                                        </Suspended>
                                                    }
                                                />
                                                <Route
                                                    path="/list-policy"
                                                    element={
                                                        <Suspended>
                                                            <StaffProtectedRoute
                                                                roles={[
                                                                    'is_operator_staff',
                                                                    'is_superuser',
                                                                    'is_operator_admin',
                                                                ]}
                                                            >
                                                                <ListChargingPolicy />
                                                            </StaffProtectedRoute>
                                                        </Suspended>
                                                    }
                                                />
                                                <Route
                                                    path="/add-policy-group"
                                                    element={
                                                        <Suspended>
                                                            <StaffProtectedRoute
                                                                roles={[
                                                                    'is_operator_staff',
                                                                    'is_superuser',
                                                                ]}
                                                            >
                                                                <AddPolicyGroup />
                                                            </StaffProtectedRoute>
                                                        </Suspended>
                                                    }
                                                />
                                                <Route
                                                    path="/list-policy-group"
                                                    element={
                                                        <Suspended>
                                                            <StaffProtectedRoute
                                                                roles={[
                                                                    'is_operator_staff',
                                                                    'is_superuser',
                                                                    'is_operator_admin',
                                                                ]}
                                                            >
                                                                <ListPolicyGroup />
                                                            </StaffProtectedRoute>
                                                        </Suspended>
                                                    }
                                                />
                                                <Route
                                                    path="/statistics"
                                                    element={
                                                        <Suspended>
                                                            <StaffProtectedRoute
                                                                roles={[
                                                                    'is_superuser',
                                                                    'is_rae_admin',
                                                                ]}
                                                            >
                                                                <StatisticsPage />
                                                            </StaffProtectedRoute>
                                                        </Suspended>
                                                    }
                                                />
                                                <Route
                                                    path="/statistics/:company_id/"
                                                    element={
                                                        <Suspended>
                                                            <StaffProtectedRoute
                                                                roles={[
                                                                    'is_superuser',
                                                                    'is_rae_admin',
                                                                ]}
                                                            >
                                                                <CompanyStatsDetails />
                                                            </StaffProtectedRoute>
                                                        </Suspended>
                                                    }
                                                />
                                                <Route
                                                    path="*"
                                                    element={<NotFound />}
                                                />{' '}
                                                {/* 404 */}
                                            </Route>
                                        </Routes>
                                    </DataProvider>
                                </AnalyticsHandler>
                            </BrowserRouter>
                            <AppCookieConsent />
                        </AuthProvider>
                    </GoogleOAuthProvider>
                </QueryClientProvider>
            </AnalyticsProvider>
            {/* <FooterComponent/> */}
        </>
    )
}

export default App
