import React, { useRef, useContext } from 'react'
import { NavLink } from 'react-router-dom'

import { Menu } from 'react-daisyui'
import { useTranslation } from 'react-i18next'
import NavbarElement from './NavbarElement'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faUserSlash,
    faSignOut,
    faUser,
    faScrewdriverWrench,
    faChartPie,
} from '@fortawesome/free-solid-svg-icons'

import { AuthContext } from '@/modules/auth'

function NavAuthComponent({ expanded }) {
    const { t } = useTranslation()
    const { user, logout } = useContext(AuthContext)

    const Avatar = () => {
        const companyImage = user.company
            ? `https://electrokinisi.yme.gov.gr/public/images/${user.company.ProfileImage}`
            : null
        const profileImage = user.profile_picture ? user.profile_picture : null

        if (companyImage)
            return (
                <div className=" m-1">
                    <img
                        src={companyImage}
                        className="shadow-inner rounded-full ring-2 ring-white"
                    />
                </div>
            )

        if (profileImage)
            return (
                <div className="m-2">
                    <img
                        src={profileImage}
                        className="shadow-inner rounded-full ring-2 max-h-[25px] ring-white"
                    />
                </div>
            )

        return (
            <div className="flex flex-row gap-1 items-center justify-center font-medium w-full ">
                <FontAwesomeIcon className="" icon={faUser} />
            </div>
        )
    }

    const MenuItems = () => {
        return (
            <>
                <li className="border-b border-blue-800">
                    <span>{user.username} &nbsp;</span>
                </li>
                <li>
                    <NavLink
                        to="/user-profile"
                        className="text-left"
                        aria-label={t('Προφίλ')}
                    >
                        <FontAwesomeIcon icon={faUser} />
                        {t('Προφίλ')}
                    </NavLink>
                </li>
                {user.is_superuser || user.is_rae_admin ? (
                    <>
                        <li>
                            <NavLink
                                to={`${window.location.origin}/admin/`}
                                className="text-left"
                                target="_blank"
                                aria-label={t('Διαχείριση')}
                            >
                                {/* externalLink={true} */}
                                <FontAwesomeIcon icon={faScrewdriverWrench} />
                                {t('Διαχείριση')}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to={`/statistics`}
                                className="text-left"
                                aria-label={t('Στατιστικά')}
                            >
                                {/* externalLink={true} */}
                                <FontAwesomeIcon icon={faChartPie} />
                                {t('Στατιστικά')}
                            </NavLink>
                        </li>
                    </>
                ) : null}

                <li>
                    <button onClick={logout} aria-label={t('Αποσύνδεση')}>
                        <FontAwesomeIcon icon={faSignOut} />
                        {t('Αποσύνδεση')}
                    </button>
                </li>
            </>
        )
    }

    const FullComponent = () => {
        if (user.isAuthenticated) {
            return (
                <div className=" dropdown dropdown-top md:dropdown-bottom dropdown-end px-4">
                    <label
                        tabIndex={0}
                        className="flex text-secondary cursor-pointer"
                    >
                        <Avatar />
                    </label>
                    <ul
                        tabIndex={0}
                        className="my-3 p-2 shadow menu menu-compact dropdown-content rounded-box bg-primary w-72 text-gray-100 z-50"
                    >
                        <MenuItems />
                    </ul>
                </div>
            )
        }

        return (
            <Menu
                horizontal
                className="p-0 tooltip tooltip-left"
                data-tip={t('Σύνδεση')}
            >
                <NavbarElement
                    to="login"
                    className="btn btn-ghost btn-circle"
                    aria-label={t('Σύνδεση')}
                >
                    <FontAwesomeIcon icon={faUserSlash} />
                </NavbarElement>
            </Menu>
        )
    }

    return <FullComponent />
}

export default NavAuthComponent
