import React, { useContext, useState, useEffect } from 'react'

import { useUserPosition } from '@/packages/position/context'
import { DataContext } from '@/modules/core/context/DataContext'
import { useTranslation } from 'react-i18next' // Import react-i18next

function LocationFilter({ ...rest }) {
    const { userPosition } = useUserPosition()
    const {
        setParamsMulti,
        params,
        applyDistance,
        setApplyDistance,
        firstPositionTrack,
        setFirstPositionTrack,
    } = useContext(DataContext)
    const { latitude, longitude } = userPosition || {
        latitude: null,
        longitude: null,
    }
    const { lat, lng, maxDistance } = params || {
        lat: null,
        lng: null,
        maxDistance: null,
    }

    const [distance, setDistance] = useState(maxDistance / 1000 || 40)
    const { t } = useTranslation() // Initialize the translation function

    // When the user location is available for the first time, set applyDistance to true
    useEffect(() => {
        if (userPosition && !applyDistance && firstPositionTrack) {
            setApplyDistance(true)
            setFirstPositionTrack(false)
        }
    }, [userPosition])

    useEffect(() => {
        if (applyDistance) {
            if (latitude && longitude && distance) {
                setParamsMulti([
                    { name: 'lat', value: latitude },
                    { name: 'lng', value: longitude },
                    { name: 'maxDistance', value: distance * 1000 },
                ])
            }
        } else {
            setParamsMulti([
                { name: 'lat', value: null },
                { name: 'lng', value: null },
                { name: 'maxDistance', value: null },
            ])
        }
    }, [applyDistance, distance, latitude, longitude])

    return (
        <div className="rounded-lg p-1 " {...rest}>
            <div className="form-control ">
                <label className="label cursor-pointer">
                    <span className="text-xs  font-normal">
                        {t('Απόσταση από εμένα')}{' '}
                    </span>
                    <input
                        type="checkbox"
                        checked={applyDistance || false}
                        className={`checkbox checkbox-success checkbox-xs ${
                            userPosition === null ? 'disabled' : ''
                        }`}
                        onChange={
                            userPosition === null
                                ? () => {}
                                : (e) => {
                                      setApplyDistance(!applyDistance)
                                  }
                        }
                        disabled={userPosition === null}
                        aria-label={t('Απόσταση από εμένα')}
                    />
                </label>
                {!userPosition ? (
                    <label className="text-xs opacity-60">
                        {t('Πρέπει να ενεργοποιήσετε την τοποθεσία σας')}{' '}
                    </label>
                ) : null}
            </div>

            {applyDistance ? (
                <div className="flex flex-col">
                    <label className="label text-sm" for="distance-from-user">
                        {t('Μέγιστη απόσταση')}
                        <span className="font-bold">{distance} Km</span>
                    </label>
                    <input
                        id="distance-from-user"
                        type="range"
                        min="0"
                        max="500"
                        step="10"
                        value={distance}
                        className="range range-xs range-secondary"
                        onChange={(e) => {
                            setDistance(e.target.value)
                        }}
                        aria-label={t('Μέγιστη απόσταση')}
                    />
                </div>
            ) : null}
        </div>
    )
}

export { LocationFilter }
