import { useCallback, useEffect, useState } from 'react'
import { GeolocatedResult, useGeolocated } from 'react-geolocated'
import { locationDidMove } from './lib/utils'
import {
    reverse_geocode as searchAddress,
    type NominatimAddressData,
} from '@/packages/geocoding'

import { create } from 'zustand'

export const useUserPositionStore = create((set) => ({
    locationLoading: false,
    setLocationLoading: (locationLoading: boolean) => set({ locationLoading }),
    userAddress: null,
    setUserAddress: (userAddress: NominatimAddressData) => set({ userAddress }),
    prevGeocodedLocation: null,
    setPrevGeocodedLocation: (prevGeocodedLocation: Coordinates) =>
        set({ prevGeocodedLocation }),
    prevGeocodingTimestamp: null,
    setPrevGeocodingTimestamp: (prevGeocodingTimestamp: number) =>
        set({ prevGeocodingTimestamp }),
    userPosition: null,
    setUserPosition: (geoloc: GeolocationCoordinates) =>
        set({ userPosition: geoloc }),
}))

/**
 * This component handles the user's position and address.
 * Warning: if a component just needs the geolocation object,
 * it should NOT use this hook, but rather the store directly:
 * import { useShallow } from 'zustand/react/shallow'
 * import {
    useUserPosition,
    useUserPositionStore,
    } from '@/packages/position/context'
 * const userPosition = useUserPositionStore(
        useShallow((state) => state.userPosition)
    )
 *
 */
function useUserPosition() {
    const [prev_coords, setPrevCoords] =
        useState<GeolocatedResult['coords']>(undefined)

    const locationLoading = useUserPositionStore(
        (state) => state.locationLoading
    )
    const setLocationLoading = useUserPositionStore(
        (state) => state.setLocationLoading
    )
    const userAddress = useUserPositionStore((state) => state.userAddress)
    const setUserAddress = useUserPositionStore((state) => state.setUserAddress)
    const prevGeocodedLocation = useUserPositionStore(
        (state) => state.prevGeocodedLocation
    )
    const setPrevGeocodedLocation = useUserPositionStore(
        (state) => state.setPrevGeocodedLocation
    )
    const prevGeocodingTimestamp = useUserPositionStore(
        (state) => state.prevGeocodingTimestamp
    )
    const setPrevGeocodingTimestamp = useUserPositionStore(
        (state) => state.setPrevGeocodingTimestamp
    )
    const userPosition = useUserPositionStore((state) => state.userPosition)
    const setUserPosition = useUserPositionStore(
        (state) => state.setUserPosition
    )

    const {
        coords,
        timestamp, // timestamp of when the last position was retrieved
        //isGeolocationAvailable, // boolean flag indicating that the browser supports the Geolocation API
        isGeolocationEnabled, // boolean flag indicating that the user has allowed the use of the Geolocation API
        positionError, // object with the error returned from the Geolocation API call
        getPosition, // a callback you can use to trigger the location query manually
    } = useGeolocated({
        positionOptions: {
            enableHighAccuracy: true,
            maximumAge: 120,
            timeout: Infinity,
        },
        watchPosition: true,
        userDecisionTimeout: undefined,
        suppressLocationOnMount: true, // don't get the location on mount
        geolocationProvider: navigator.geolocation,
        isOptimisticGeolocationEnabled: false, // assume the user won't allow geolocation
        watchLocationPermissionChange: true,
        // onError,
        // onSuccess
    })

    const startTracking = useCallback(() => {
        setLocationLoading(true)
        getPosition()
    }, [])

    useEffect(() => {
        setLocationLoading(false)
    }, [coords, positionError])

    const coordsDidChange = ({
        coords,
        prev_coords,
    }: {
        coords: GeolocatedResult['coords']
        prev_coords: GeolocatedResult['coords']
    }) => {
        if (!prev_coords) return true
        if (!coords) return false
        return JSON.stringify(coords) !== JSON.stringify(prev_coords)
    }

    // When the user location changes, request an address from nominatim
    useEffect(() => {
        if (!coords) return

        const didchange = coordsDidChange({ coords, prev_coords })
        if (!didchange) return

        setUserPosition(coords)
        setPrevCoords(coords)

        if (!locationDidMove(prevGeocodedLocation, coords, 1)) return

        // Don't geocode if the last geocoding was less than 1 minute ago
        if (
            prevGeocodingTimestamp &&
            timestamp - prevGeocodingTimestamp < 60000
        )
            return

        const { latitude, longitude } = coords

        searchAddress({ lat: latitude, lng: longitude })
            .then((address) => {
                setUserAddress(address)
                setPrevGeocodingTimestamp(timestamp)
            })
            .catch((err) => {
                setUserAddress(null)
                console.error('Error getting address:', err)
            })
            .finally(() => {
                setPrevGeocodedLocation(coords)
            })
    }, [coords])

    // Create the context value object
    return {
        startTracking,
        isTracking: isGeolocationEnabled,
        locationError: positionError,
        locationLoading,
        userPosition,
        userAddress,
    }
}

export { useUserPosition }
