/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CommercialEVSearchResults } from '../models/CommercialEVSearchResults';
import type { EV } from '../models/EV';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class VehiclesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Search for available vehicles from the EV Database export
     * @returns CommercialEVSearchResults 
     * @throws ApiError
     */
    public vehiclesSearchList({
vehicleMake,
vehicleMakeIcontains,
vehicleModel,
vehicleModelIcontains,
availabilityDateFrom,
availabilityDateFromGte,
availabilityDateFromLte,
batteryCapacityFull,
batteryCapacityFullGte,
batteryCapacityFullLte,
chargePlug,
fastchargePlug,
chargeStandardPower,
chargeStandardPowerGte,
chargeStandardPowerLte,
fastchargePowerAvg,
fastchargePowerAvgGte,
fastchargePowerAvgLte,
q,
ordering,
}: {
/**
 * vehicle_make
 */
vehicleMake?: string,
/**
 * vehicle_make__icontains
 */
vehicleMakeIcontains?: string,
/**
 * vehicle_model
 */
vehicleModel?: string,
/**
 * vehicle_model__icontains
 */
vehicleModelIcontains?: string,
/**
 * availability_date_from
 */
availabilityDateFrom?: string,
/**
 * availability_date_from__gte
 */
availabilityDateFromGte?: string,
/**
 * availability_date_from__lte
 */
availabilityDateFromLte?: string,
/**
 * battery_capacity_full
 */
batteryCapacityFull?: string,
/**
 * battery_capacity_full__gte
 */
batteryCapacityFullGte?: string,
/**
 * battery_capacity_full__lte
 */
batteryCapacityFullLte?: string,
/**
 * charge_plug
 */
chargePlug?: string,
/**
 * fastcharge_plug
 */
fastchargePlug?: string,
/**
 * charge_standard_power
 */
chargeStandardPower?: string,
/**
 * charge_standard_power__gte
 */
chargeStandardPowerGte?: string,
/**
 * charge_standard_power__lte
 */
chargeStandardPowerLte?: string,
/**
 * fastcharge_power_avg
 */
fastchargePowerAvg?: string,
/**
 * fastcharge_power_avg__gte
 */
fastchargePowerAvgGte?: string,
/**
 * fastcharge_power_avg__lte
 */
fastchargePowerAvgLte?: string,
/**
 * q
 */
q?: string,
/**
 * Which field to use when ordering the results.
 */
ordering?: string,
}): CancelablePromise<Array<CommercialEVSearchResults>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/vehicles/search/',
            query: {
                'vehicle_make': vehicleMake,
                'vehicle_make__icontains': vehicleMakeIcontains,
                'vehicle_model': vehicleModel,
                'vehicle_model__icontains': vehicleModelIcontains,
                'availability_date_from': availabilityDateFrom,
                'availability_date_from__gte': availabilityDateFromGte,
                'availability_date_from__lte': availabilityDateFromLte,
                'battery_capacity_full': batteryCapacityFull,
                'battery_capacity_full__gte': batteryCapacityFullGte,
                'battery_capacity_full__lte': batteryCapacityFullLte,
                'charge_plug': chargePlug,
                'fastcharge_plug': fastchargePlug,
                'charge_standard_power': chargeStandardPower,
                'charge_standard_power__gte': chargeStandardPowerGte,
                'charge_standard_power__lte': chargeStandardPowerLte,
                'fastcharge_power_avg': fastchargePowerAvg,
                'fastcharge_power_avg__gte': fastchargePowerAvgGte,
                'fastcharge_power_avg__lte': fastchargePowerAvgLte,
                'q': q,
                'ordering': ordering,
            },
        });
    }

    /**
     * Retrieve a single vehicle from the EV Database export
     * @returns EV 
     * @throws ApiError
     */
    public vehiclesSearchRead({
uuid,
}: {
/**
 * A UUID string identifying this Όχημα (EV Database).
 */
uuid: string,
}): CancelablePromise<EV> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/vehicles/search/{uuid}/',
            path: {
                'uuid': uuid,
            },
        });
    }

    /**
     * Retrieve all vehicles associated with the user
     * @returns any 
     * @throws ApiError
     */
    public vehiclesUserList(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/vehicles/user/',
        });
    }

    /**
     * Add a vehicle from EV Database to the user's vehicles
     * @returns any 
     * @throws ApiError
     */
    public vehiclesUserAssignCommercial({
uuid,
}: {
uuid: string,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/vehicles/user/assign-commercial/{uuid}/',
            path: {
                'uuid': uuid,
            },
        });
    }

    /**
     * Add a custom vehicle to the user's vehicles
     * @returns any 
     * @throws ApiError
     */
    public vehiclesUserAssignCustom(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/vehicles/user/assign-custom/',
        });
    }

    /**
     * Remove a vehicle from the user's vehicles
     * @returns void 
     * @throws ApiError
     */
    public vehiclesUserRemove({
uuid,
}: {
uuid: string,
}): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/vehicles/user/remove/{uuid}/',
            path: {
                'uuid': uuid,
            },
        });
    }

    /**
     * Set a vehicle as default for the user
     * @returns any 
     * @throws ApiError
     */
    public vehiclesUserSetDefault({
uuid,
}: {
uuid: string,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/vehicles/user/set-default/{uuid}/',
            path: {
                'uuid': uuid,
            },
        });
    }

}
