/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type EV = {
    readonly uuid?: string;
    vehicle_make: string;
    vehicle_model: string;
    vehicle_model_version?: string | null;
    availability_status?: number | null;
    availability_date_from?: string | null;
    availability_date_from_estimate?: boolean | null;
    availability_date_to?: string | null;
    charge_plug: string;
    charge_plug_estimate?: boolean | null;
    charge_plug_location?: string | null;
    charge_plug_2_location?: string | null;
    charge_plug_2_optional?: boolean | null;
    charge_standard_power: number;
    charge_standard_phase?: number | null;
    charge_standard_phaseamp?: number | null;
    charge_standard_chargetime?: number | null;
    charge_standard_chargespeed?: number | null;
    charge_standard_estimate?: boolean | null;
    charge_standard_table?: any;
    charge_alternative_power?: number | null;
    charge_alternative_phase?: number | null;
    charge_alternative_phaseamp?: number | null;
    charge_alternative_chargetime?: number | null;
    charge_alternative_chargespeed?: number | null;
    charge_alternative_table?: any;
    charge_option_power?: number | null;
    charge_option_phase?: number | null;
    charge_option_phaseamp?: number | null;
    charge_option_chargetime?: number | null;
    charge_option_chargespeed?: number | null;
    charge_option_table?: any;
    fastcharge_plug?: string | null;
    fastcharge_plug_estimate: boolean;
    fastcharge_plug_location?: string | null;
    /**
     * kW
     */
    fastcharge_power_max?: number | null;
    /**
     * kW
     */
    fastcharge_power_avg?: number | null;
    /**
     * min
     */
    fastcharge_chargetime?: number | null;
    /**
     * km/h
     */
    fastcharge_chargespeed?: number | null;
    fastcharge_optional?: boolean | null;
    fastcharge_autocharge?: boolean | null;
    fastcharge_iso15118_pnc?: string | null;
    fastcharge_iso15118_pnc_support_2?: string | null;
    fastcharge_iso15118_pnc_support_20?: string | null;
    fastcharge_estimate?: boolean | null;
    fastcharge_fastned_tested?: boolean | null;
    fastcharge_table?: any;
    battery_capacity_useable: number;
    battery_capacity_full: number;
    battery_capacity_estimate?: string | null;
    battery_type?: string | null;
    battery_tms?: string | null;
    battery_chemistry?: string | null;
    battery_manufacturer?: string | null;
    battery_architecture?: string | null;
    battery_voltage_nominal?: number | null;
    battery_modules?: number | null;
    battery_cells?: string | null;
    battery_weight?: number | null;
    battery_warranty_period?: string | null;
    battery_warranty_mileage?: string | null;
    related_vehicle_id_succesor?: number | null;
    cc_kind: EV.cc_kind;
};

export namespace EV {

    export enum cc_kind {
        BEV = 'BEV',
        PHEV = 'PHEV',
    }


}
