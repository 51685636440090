import React from 'react'
import { useTranslation } from 'react-i18next'

function VersionBanner() {
    const app_version = import.meta.env.VITE_APP_VERSION || 'beta'

    return <p> v.{app_version}</p>
}

function BetaBanner() {
    const { t } = useTranslation()
    const app_version = import.meta.env.VITE_APP_VERSION || 'beta'

    return (
        <span
            style={{
                letterSpacing: '1px',
            }}
            className="mx-2 text-xl text-sky-500 tooltip tooltip-bottom font-normal"
            data-tip={t('home.version_banner')}
        >
            {' '}
            beta
        </span>
    )
}

export { BetaBanner, VersionBanner }
