import React from 'react'
import { useTranslation } from 'react-i18next'

import GreekFlag from '@/assets/img/greek.png'
import EnglishFlag from '@/assets/img/english.png'

function LanguageControl({ className, noIcon, small, ...rest }) {
    const { i18n } = useTranslation()

    const languageFlag = {
        en: EnglishFlag,
        gr: GreekFlag,
    }

    const LanguageButton = ({ language }) => {
        const isCurrentLang = i18n.language === language
        const changeLanguage = (lng) => {
            i18n.changeLanguage(lng)
        }

        const languageName = {
            en: 'English',
            gr: 'Ελληνικά',
        }

        return (
            <button
                className={
                    ` min-w-max w-full font-medium hover:ring` +
                    (isCurrentLang ? '  underline' : '')
                }
                onClick={() => changeLanguage(language)}
            >
                <img src={languageFlag[language]} className="w-6" />
                <span className="ml-1">{languageName[language]}</span>
            </button>
        )
    }

    return (
        <div className={`dropdown dropdown-end ${className ? className : ''}`}>
            <div
                tabIndex={0}
                role="button"
                className={`${small ? 'p-1 text-xs' : 'p-2'} `}
            >
                <img src={languageFlag[i18n.language]} className="w-6 " />
            </div>
            <ul
                tabIndex={0}
                className="menu menu-sm gap-1 dropdown-content mt-3 z-[1] p-2 shadow bg-[#0f2254] border border-primary rounded-box text-white"
            >
                <li className="flex flex-row ">
                    <LanguageButton language="en" />
                </li>

                <li className="flex flex-row">
                    <LanguageButton language="gr" />
                </li>
            </ul>
        </div>
    )
}

export default LanguageControl
