export type NominatimAddressData = {
    place_id: number
    licence: string
    osm_type: string
    osm_id: number
    boundingbox: [string, string, string, string]
    lat: string
    lon: string
    display_name: string
    class: string
    type: string
    importance: number
    address: {
        road: string
        suburb: string
        city: string
        municipality: string
        county: string
        state: string
        country: string
        postcode: string
        country_code: string
    }
}

export async function reverse_geocode({
    lat,
    lng,
}: {
    lat: number
    lng: number
}): Promise<NominatimAddressData | null> {
    const url = `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lng}`

    return fetch(url, {
        method: 'GET',
        referrer: `${window.location.origin}`,
        headers: {
            'X-Requested-With': `${window.location.origin}`,
        },
    })
        .then((res) => res.json())
        .then((data: NominatimAddressData) => {
            return data
        })
        .catch((err) => {
            console.error('Error getting address:', err)
            return null
        })
}

export async function geocode({
    query,
}: {
    query: string
}): Promise<NominatimAddressData[]> {
    const url = `https://nominatim.openstreetmap.org/search?format=json&countrycodes=gr&q=${query}`

    return fetch(url, {
        method: 'GET',
        referrer: `${window.location.origin}`,
        headers: {
            'X-Requested-With': `${window.location.origin}`,
        },
    })
        .then((res) => res.json())
        .then((data: NominatimAddressData[]) => {
            return data
        })
        .catch((err) => {
            console.error('Error getting address:', err)
            return []
        })
}
