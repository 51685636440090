import { useContext } from 'react'
import { MapContext, type MapContextProps } from './context'

/**
 * Consume map context
 */
export const useMap = (): MapContextProps => {
    const context = useContext(MapContext)

    if (!context) {
        throw new Error('useMap must be used within a MapProvider')
    }

    return context
}
