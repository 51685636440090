import React, { createContext, useState, useRef } from 'react'
import { styleBasedOnTime } from './utils'

export interface MapContextProps {
    mapContainer: React.RefObject<HTMLDivElement> | null
    currentMap?: maplibregl.Map
    setCurrentMap: (map?: maplibregl.Map) => void
    mapStyle: string
    setMapStyle: any
    showMap: boolean
    setShowMap: any
    loading: boolean
    setLoading: any
}

//  @ts-ignore
const MapContext = createContext<MapContextProps>()

// Provider component
const MapProvider = function MapProvider({
    children,
}: {
    children: React.ReactNode
}) {
    const mapContainer = useRef(null)
    const [currentMap, setCurrentMap] = useState(undefined)
    const [loading, setLoading] = useState(true)

    // Map style
    const [mapStyle, setMapStyle] = useState(styleBasedOnTime())

    // View (map or list)
    const [showMap, setShowMap] = useState(false)

    // Create the context value object
    const mapContextValue = {
        mapContainer,
        currentMap,
        setCurrentMap,
        mapStyle,
        setMapStyle,
        showMap,
        setShowMap,
        loading,
        setLoading,
    }

    return (
        <MapContext.Provider value={mapContextValue}>
            {children}
        </MapContext.Provider>
    )
}

export { MapProvider, MapContext }
