import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { BetaBanner } from '@/modules/core/components/VersionBanner'

import { AuthContext } from '@/modules/auth'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faChevronCircleRight,
    faChevronRight,
} from '@fortawesome/free-solid-svg-icons'

import ChargingLogo from '@/assets/logo.webp'
import { RaaeyLogo } from '@/modules/core/components/RaaeyLogo'
import { GetLogo } from '@/modules/core/components/GetLogo'
import { useQuery } from '@tanstack/react-query'

import Footer from '@/modules/core/components/Footer'

function Hero() {
    const { t } = useTranslation()

    return (
        <section className="container l-g:max-w-7xl w-full py-10 md:pb-4">
            <div className=" px-3 min-h-[calc(100vh-64px)] md:min-h-max flex flex-col justify-center gap-10 md:gap-20">
                <div className="flex flex-col justify-center md:grid grid-cols-3 gap-14 w-full">
                    <div className="h-max col-span-2 text-center md:text-left">
                        <div className="flex flex-col md:flex-row items-center justify-center md:justify-start gap-2 mb-3">
                            <img
                                src={ChargingLogo}
                                alt="ChargingCost Logo"
                                className="w-24 md:w-16"
                            />
                            <h1
                                className="text-5xl md:text-6xl xl:text-7xl font-bold "
                                style={{
                                    letterSpacing: '-5px',
                                }}
                            >
                                ChargingCost
                                <BetaBanner />
                            </h1>
                        </div>

                        <h2 className="text-xl mb-14  text-gray-600">
                            {t('home.subtitle')}
                        </h2>
                        <Link
                            to="dashboard"
                            className="group bg-secondary text-white px-6 py-4 rounded-md transition-all hover:bg-secondary-600 font-bold text-xl hover:bg-primary focus:ring"
                        >
                            &nbsp; {t('home.cta')}
                            <FontAwesomeIcon
                                icon={faChevronRight}
                                className="ml-2 transition-all w-0 group-hover:w-5 "
                            />
                        </Link>
                    </div>

                    <div className="flex flex-col justify-start md:justify-center gap-0 items-center md:items-end border-t md:border-none border-primary">
                        <RaaeyLogo className="w-36 md:w-64" />
                    </div>
                </div>
                <GetmapCredits />
            </div>
        </section>
    )
}

function Description() {
    const { AuthenticatedAPI } = useContext(AuthContext)
    const { t, i18n } = useTranslation()
    const language = i18n.language

    const { data, isLoading } = useQuery({
        queryKey: [`home_description_${language}`],
        queryFn: () =>
            AuthenticatedAPI.chargingCost.chargingCostApplicationSettingsRead({
                name: `home_description_${language}`,
            }),
        enabled: true,
        retry: 3,
    })

    return (
        <section className="container l-g:max-w-7xl w-full border-secondary shadow bg-secondary bg-opacity-[0.03] py-4">
            <div className="px-6 w-full pt-5">
                <h2 className="text-2xl font-bold mb-4">
                    {t('home.description_title')}
                </h2>
                {data && (
                    <div
                        className="mb-8"
                        dangerouslySetInnerHTML={{ __html: data.value }}
                    />
                )}

                <div className="w-full flex gap-3 items-baseline">
                    <Link
                        to="disclaimer"
                        className="link font-bold text-xs text-blue-900 ml-auto"
                    >
                        {t('disclaimer.title')}
                    </Link>

                    <Link to="about" className="link font-bold  text-blue-900">
                        {t('Περισσότερα')}

                        <FontAwesomeIcon
                            icon={faChevronCircleRight}
                            className="ml-2"
                        />
                    </Link>
                </div>
            </div>
        </section>
    )
}

function Actions() {
    const { t } = useTranslation()
    return (
        <section className="container mx-auto py-12 w-full">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div>
                    <h3 className="text-2xl font-bold mb-4">{t('Εγγραφή')}</h3>
                    <p className="mb-8">
                        {t(
                            'Πραγματοποιώντας εγγραφή, μπορείτε να επιλέξετε τον τύπο του ηλεκτρικού σας οχήματος, και να λαμβάνετε εξατομικευμένα αποτελέσματα'
                        )}
                    </p>

                    <Link
                        to="signup"
                        className="btn btn-outline btn-secondary"
                        href=""
                    >
                        {t('Εγγραφή')}
                    </Link>
                </div>
                {/* <div>
                        <h3 className="text-2xl font-bold mb-4">Android/iOS</h3>
                        <p className="mb-8">
                            {t(
                                'Κάντε λήψη της εφαρμογής για Android/iOS, για να συγκρίνετε τις τιμές των διαθέσιμων σημείων φόρτισης εύκολα και γρήγορα'
                            )}
                        </p>
                        <Button color="secondary" size="md">
                            {t('Λήψη')}
                        </Button>
                    </div> */}
            </div>
        </section>
    )
}

function GetmapCredits() {
    return (
        // <GetLogo isHomepage />

        <section className="container l-g:max-w-7xl w-full">
            <div className="flex justify-center md:justify-end w-full">
                <GetLogo isHomepage />
            </div>
        </section>
    )
}

function HomePage() {
    return (
        <div className="min-h-screen pb-[84px] md:pt-[64px] md:pb-10 flex flex-col justify-center mx-4 overflow-x-hidden">
            <main className="h-full flex flex-col justify-center gap-10 ">
                <Hero />
                <Description />

                <Actions />
                {/* <GetmapCredits /> */}
            </main>
            <Footer />
        </div>
    )
}

export default HomePage
