import React, { useEffect } from 'react'
import maplibregl from 'maplibre-gl'
import { createRoot } from 'react-dom/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faUser } from '@fortawesome/free-solid-svg-icons'

import { useMap } from '@/modules/map'
import { useUserPosition } from '../context'

function zoomToMyLocation(map, locationInstance) {
    map.flyTo({
        center: {
            lng: locationInstance.longitude,
            lat: locationInstance.latitude,
        },
        zoom: 12,
        speed: 1,
        curve: 1,
        easing(t) {
            return t
        },
    })
}

function HeadingArrow({ heading, mapRotation }) {
    heading = heading - mapRotation

    return (
        <div
            className="absolute top-0 bottom-0 m-auto w-20 h-20 border-2  border-primary flex flex-col text-xs text-blue-900 text-center tooltip tooltip-primary tooltip-top"
            data-tip={`Κατεύθυνση: ${heading}°`}
            style={{
                transform: `rotate(${heading}deg)`,
                transition: 'transform 0.2s',
            }}
        >
            <FontAwesomeIcon
                icon={faChevronUp}
                className="mb-6 font-bold text-3xl"
            />
        </div>
    )
}

function Marker({ speed, heading, locationInstance, map }) {
    return (
        <div className="flex flex-col items-center content-center ">
            <div
                className={`flex flex-col items-center content-center rounded-full h-10 w-10 `}
            >
                {/* {map.getBearing() && heading !== undefined && speed > 0 ? (
                    <HeadingArrow
                        heading={heading}
                        mapRotation={map.getBearing()}
                    />
                ) : null} */}
                <div className="absolute top-0 bottom-0 left-0 right-0 rounded-full bg-blue-600 bg-opacity-50 animate-pulse "></div>

                <div
                    className="user-location-marker m-auto w-8 h-8 flex flex-col text-xs text-white text-center shadow-lg border-2 border-white rounded-full bg-blue-900 cursor-pointer hover:bg-primary tooltip tooltip-primary tooltip-top"
                    data-tip={`Η θέση μου (ακρίβεια ${locationInstance.accuracy} m)`}
                    onClick={() => {
                        zoomToMyLocation(map, locationInstance)
                        //setExpanded(!expanded)
                    }}
                >
                    <FontAwesomeIcon icon={faUser} className="m-auto " />
                </div>
            </div>
        </div>
    )
}

export function UserPositionMarker() {
    const { currentMap } = useMap()
    const { userPosition } = useUserPosition()

    // Update user location marker
    useEffect(() => {
        if (!userPosition || !currentMap) return

        // Create and update the marker
        const markerElement = document.createElement('div')
        const markerReactRoot = createRoot(markerElement)
        markerReactRoot.render(
            <Marker
                locationInstance={userPosition}
                heading={userPosition.heading}
                speed={userPosition.speed}
                map={currentMap}
            />
        )

        const marker = new maplibregl.Marker({
            element: markerElement,
            offset: [0, -12],
        })
            .setLngLat([userPosition.longitude, userPosition.latitude])
            .addTo(currentMap)
        // Cleanup the marker when the component unmounts or the user location changes
        return () => {
            marker.remove()
        }
    }, [userPosition, currentMap])
}
