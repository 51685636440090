import MapLibreGlDirections, {
    LoadingIndicatorControl,
} from '@maplibre/maplibre-gl-directions'
import DistanceMeasurementMapLibreGlDirections, {
    config as DirectionsConfig,
} from './maplibre-gl-directions-config'

function getDirectionsAPI({ showDist }) {
    const additionalOptions = {
        unit: 'metric', // or 'imperial'
        api: `${window.location.origin}${
            //@ts-ignore
            import.meta.env.VITE_BACKEND_ROOT || ''
        }/route/v1`,
        profile: 'driving', // profile for routing, e.g., 'driving', 'cycling', 'walking'
        controls: {
            inputs: true,
            instructions: true,
            profileSwitcher: true,
        },
        styles: [
            // Customize the appearance of the directions line
            {
                id: 'directions-line',
                type: 'line',
                paint: {
                    'line-color': '#00b4ff',
                    'line-width': 4,
                },
            },
        ],
        alternatives: true, // Enable multiple route alternatives
        interactive: true, // Enable user interaction with the directions control
        flyTo: true, // Fly to the start of the route when selected
        steps: true, // Show turn-by-turn instructions
        showErrors: true, // Show errors in the directions control
        requestOptions: {
            alternatives: 'true',
            overview: 'full', // Simplify the route line, 1 = no overview, 2+ = full overview
            geometries: 'geojson',
        },
    }

    const options = showDist
        ? { ...additionalOptions, ...DirectionsConfig }
        : additionalOptions
    const api = showDist
        ? DistanceMeasurementMapLibreGlDirections
        : MapLibreGlDirections

    return {
        Api: api,
        options,
    }
}

function InitDirectionsAPI(
    map,
    { apiOptions, loadingIndicator, setTotalDistance, setTotalDuration }
) {
    const { Api, options } = getDirectionsAPI(apiOptions)

    const instance = new Api(map, options)
    instance.interactive = false

    // Optionally add the standard loading-indicator control
    if (loadingIndicator) {
        map.addControl(new LoadingIndicatorControl(instance))
    }

    window.directions = instance
    return instance
}

export { getDirectionsAPI, InitDirectionsAPI }
