import React from 'react'
import { RaaeyLogo } from '@/modules/core/components/RaaeyLogo'

export function LoadingScreen() {
    const [progress, setProgress] = React.useState(0)
    React.useEffect(() => {
        const interval = setInterval(() => {
            setProgress((prev) => {
                if (prev >= 100) {
                    return 100
                }
                return prev + 1
            })
        }, 50)
        return () => clearInterval(interval)
    }, [])

    return (
        <div className="flex flex-col gap-3 h-full w-full items-center justify-center p-2">
            <RaaeyLogo className="h-24 lg:h-28" />
            {/* Progress bar */}
            <div className="w-64 h-2 bg-gray-200 rounded-full">
                <div
                    className="h-full bg-secondary rounded-full animate-pulse"
                    style={{
                        width: `${progress}%`,
                        transition: 'width 0.5s',
                    }}
                ></div>
            </div>
            <label htmlFor="" className="text-primary">
                Loading . . .
            </label>
        </div>
    )
}
