/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CommercialEVSearchResults = {
    readonly uuid?: string;
    vehicle_make: string;
    vehicle_model: string;
    vehicle_model_version?: string | null;
    availability_date_from?: string | null;
    charge_plug: string;
    charge_standard_power: number;
    fastcharge_plug?: string | null;
    /**
     * kW
     */
    fastcharge_power_max?: number | null;
    cc_kind: CommercialEVSearchResults.cc_kind;
    readonly details?: string;
};

export namespace CommercialEVSearchResults {

    export enum cc_kind {
        BEV = 'BEV',
        PHEV = 'PHEV',
    }


}
